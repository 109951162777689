import React from "react";
import { Box, Tabs, Tab } from "@mui/material";
import { useQuery } from "@apollo/client";
import PlainAdminHeader from "./UI/PlainAdminHeader";
import {
  GET_ARTICLES,
  GET_PENDING_ARTICLES,
} from "../../graphql/other-queries";
import { useState } from "react";
import { useEffect } from "react";
import VerifiedArticleTable from "./Articles/VerifiedArticleTable";
import PendingArticleTable from "./Articles/PendingArticleTable";
import AuthorsTable from "./Articles/AuthorsTable";
// import { card1, card2 } from "./UI/GetStats";

const Article = () => {
  const [verifiedArticles, setVerifiedArticles] = useState("");
  const [pendingArticles, setPendingArticles] = useState("");
  const [authors, setAuthors] = useState([]);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { data: verifiedData } = useQuery(GET_ARTICLES, {
    pollInterval: 500,
  });

  const { data: pendingData } = useQuery(GET_PENDING_ARTICLES, {
    pollInterval: 500,
  });

  // Get All Verified Articles
  useEffect(() => {
    if (verifiedData) {
      // console.log(verifiedData.getArticles);
      setVerifiedArticles(verifiedData.getArticles);
    }
  }, [verifiedData]);

  // Get All Verified Articles
  useEffect(() => {
    if (pendingData) {
      // console.log(pendingData.getPendingArticles);
      setPendingArticles(pendingData.getPendingArticles);
    }
  }, [pendingData]);

  // Get all authors names and emails
  useEffect(() => {
    if (verifiedData) {
      // Extract all the author names and emails into separate arrays
      const authorsArray = verifiedData.getArticles.map((author) => ({
        author: author.author, // Assuming the author object has a 'name' property
        email: author.email,
      }));

      // Use a Set to remove duplicates (based on email)
      const uniqueAuthors = Array.from(
        new Set(authorsArray.map((author) => author.email))
      ).map((email) => {
        // Find the first author with the matching email to get the name
        const author = authorsArray.find((a) => a.email === email);
        return {
          author: author.author,
          email: email,
        };
      });

      // Now, uniqueAuthors contains all the unique author names and emails
      // console.log("Unique", uniqueAuthors);
      setAuthors(uniqueAuthors);
    }
  }, [verifiedData]);

  return (
    <PlainAdminHeader>
      <Box className="mt-16">
        <Box className="pt-5" display="flex" justifyContent="center">
          <Tabs value={value} onChange={handleChange} className="">
            <Tab label="Verified Articles" className="" />
            <Tab label="Pending Articles" />
            <Tab label="Verified Authors" />
          </Tabs>
        </Box>
        {/* Content for the selected tab */}
        <Box className="py-8">
          {value === 0 && <VerifiedArticleTable data={verifiedArticles} />}
          {value === 1 && <PendingArticleTable data={pendingArticles} />}
          {value === 2 && <AuthorsTable data={authors} />}
        </Box>
      </Box>
    </PlainAdminHeader>
  );
};

export default Article;
