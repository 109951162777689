import React from "react";
import { Box, Tabs, Tab, Fab } from "@mui/material";
import { useQuery } from "@apollo/client";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import PartnersTable from "./Partners/PartnersTable";
import { useAuth } from "../../context/app.context";
import { GET_PARTNERS } from "../../graphql/admin-queries";
import PlainAdminHeader from "./UI/PlainAdminHeader";
import CreatePartner from "./Partners/CreatePartner";
import AddPartnersMedia from "./Partners/AddPartnersMedia";

// import { card1, card2 } from "./UI/GetStats";

const Partners = () => {
  let navigate = useNavigate();

  const { adminData } = useAuth();

  const [partners, setPartners] = useState("");

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { data: partnersdata } = useQuery(GET_PARTNERS, {
    pollInterval: 500,
  });

  // Get All Events
  useEffect(() => {
    if (partnersdata) {
      // console.log(partnersdata.getMinorAdmins);
      setPartners(partnersdata.getPartners);
    }
  }, [partnersdata]);

  // console.log(adminData.role, "role");

  return (
    <PlainAdminHeader>
      <Box className="mt-16">
        <Box
          className={`pt-5 md:w-[95%] lg:w-[93%] justify-center md:justify-end`}
          display="flex"
        ></Box>
        <Box className="" display="flex" justifyContent="center">
          <Tabs value={value} onChange={handleChange} className="">
            <Tab label="Manage Partners" className="" />
            <Tab label="Create Partner" />
            {/* <Tab label="Add Partner Media" /> */}
          </Tabs>
        </Box>
        {/* Content for the selected tab */}
        <Box className="py-8">
          {value === 0 && <PartnersTable data={partners} />}
          {value === 1 && <CreatePartner />}
          {/* {value === 2 && <AddPartnersMedia />} */}
        </Box>
      </Box>
    </PlainAdminHeader>
  );
};

export default Partners;
