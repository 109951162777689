import { Box } from "@mui/system";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import PagesHeader from "../custom/Layout/PagesHeader";

export const PageError = () => {
  return (
    <Fragment>
      <PagesHeader
        imgSrc={`${process.env.REACT_APP_S3_BUCKET_URL}/designs/lost.png`}
      >
        <Box className="p-8">
          {/* Header */}

          <Box className="items-center ">
            <Box className="text-center ">
              <div className="text-base md:text-2xl font-bricolage">
                <img
                  src={`${process.env.REACT_APP_S3_BUCKET_URL}/designs/404.png`}
                  alt="lost"
                  className="!h-[55%] !w-[55%] mx-auto"
                />
                Oops.... Looks like you're lost, Let's help you go back to
                <Link
                  to={"/"}
                  className="text-base md:text-2xl font-bricolage underline ml-2 font-secondary"
                >
                  Dashboard
                </Link>
              </div>
            </Box>
          </Box>
        </Box>
        <br />
        <br />
        <br />
      </PagesHeader>
    </Fragment>
  );
};
