import React, { useState } from "react";
import {
  Alert,
  Box,
  CircularProgress,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import CRCTextField from "../../custom/Forms/CRCTextField";
import CRCSelect from "../../custom/Forms/CRCSelect";
import CRCbutton from "../../custom/Forms/CRCbutton";
import { Link, useNavigate } from "react-router-dom";
import CRCFileUpload from "../../custom/Forms/CRCFileUpload";
import { useMutation } from "@apollo/client";
import { SIGNUP_ADMIN } from "../../graphql/admin-queries";
import { useContext } from "react";
import { LoaderContext } from "../../context/loader.context";
import PageLoader from "../../custom/Layout/PageLoader";

const roles = ["editor", "admin"];

const SignUpForm = () => {
  const loaderContext = useContext(LoaderContext);

  const openLoader = () => {
    loaderContext.setShowState(true);
  };

  const closeLoader = () => {
    loaderContext.setShowState(false);
  };

  const [signUpAdmin, { loading, error, data }] = useMutation(SIGNUP_ADMIN);
  const [msg, setMsg] = useState("");
  let navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    avatar: "",
    email: "",
    role: "",
    password: "",
  });

  const [inputAvatarValue, setInputAvatarValue] = useState("");

  const handleAvatarChange = (event) => {
    const selectedFile = event.target.files[0];
    setMsg("");

    if (selectedFile) {
      setFormData({ ...formData, avatar: selectedFile });
      setInputAvatarValue(selectedFile.name);
    }
  };
  const handleCountryChange = (selectedCountry) => {
    setFormData({ ...formData, role: selectedCountry });
    setMsg("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emptyFields = Object.keys(formData).filter((key) => !formData[key]);

    if (emptyFields.length > 0) {
      // Display error alert for empty fields
      setMsg("Please input all fields");
      return;
    }

    try {
      const { data } = await signUpAdmin({
        variables: formData,
      });

      openLoader();
      // Handle successful form submission (if needed)
      // console.log("Signup success:", data);

      if (data.signUpAdmin.email === null) {
        closeLoader();
        setMsg("Email already exists");
      } else {
        closeLoader();
        setMsg("Admin created successfully");
        setTimeout(() => {
          navigate(`/admins`);
        }, 3000);
      }
    } catch (error) {
      // Display error alert for server error
      console.error("Signup error:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormGroup>
        {loading && <CircularProgress />} {/* Display loading indicator */}
        {msg === "Email already exists" ? (
          <Alert variant="filled" severity="error">
            {msg}
          </Alert>
        ) : msg === "Please input all fields" ? (
          <Alert variant="filled" severity="error">
            {msg}
          </Alert>
        ) : msg === "Admin created successfully" ? (
          <Alert variant="filled" severity="success">
            {msg}
          </Alert>
        ) : null}
        <br />
        {/* Display error alert */}
        <Grid container spacing={{ xs: 0, sm: 2, md: 2 }}>
          <Grid item xs={12} md={6}>
            <CRCTextField
              label="First Name"
              name="fname"
              type="name"
              onChange={(e) => {
                setFormData({ ...formData, firstname: e.target.value });
                setMsg("");
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CRCTextField
              label="Last Name"
              name="lname"
              type="name"
              onChange={(e) => {
                setFormData({ ...formData, lastname: e.target.value });
                setMsg("");
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, md: 2 }} className="">
          <Grid item xs={12} md={6}>
            <CRCTextField
              label="Email"
              name="email"
              type="email"
              onChange={(e) => {
                setFormData({ ...formData, email: e.target.value });
                setMsg("");
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CRCTextField
              label="Password"
              name="password"
              type="password"
              onChange={(e) => {
                setFormData({ ...formData, password: e.target.value });
                setMsg("");
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={{ xs: 0, md: 2 }} className="">
          <Grid item xs={12} md={6}>
            {/* <CRCTextField
              label="Role"
              name="role"
              type="name"
              onChange={(e) => {
                setFormData({ ...formData, role: e.target.value });
                setMsg("");
              }}
            /> */}
            <CRCSelect
              label="Select a role"
              values={roles} // Assuming role is an array of countries
              placeholder="Select a role"
              onChange={handleCountryChange} // Pass the handler to update the selected country
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CRCFileUpload
              label="Select an image"
              name="avatar"
              type="file"
              value={inputAvatarValue}
              onChange={handleAvatarChange}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <Box className="mx-auto mt-8">
            <CRCbutton label={"Submit"} type={"submit"} />
          </Box>
        </Grid>
        {/* <Grid item className="px-2 flex place-content-end">
          <Link to="/signin" variant="body">
            <Typography variant="subtitle" color="initial">
              Already have an account?
              <span className="underline pl-2">Sign In</span>
            </Typography>
          </Link>
        </Grid> */}
        {loaderContext.showState && <PageLoader />}
      </FormGroup>
    </form>
  );
};

export default SignUpForm;
