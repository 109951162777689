import React from "react";
import { Box, Tabs, Tab } from "@mui/material";
import { useQuery } from "@apollo/client";
import PlainAdminHeader from "./UI/PlainAdminHeader";
import { GET_EVENTS } from "../../graphql/other-queries";
import { useState } from "react";
import { useEffect } from "react";
import EventTable from "./Events/EventTable";
import AddEvent from "./Events/AddEvent";
// import { card1, card2 } from "./UI/GetStats";

const Event = () => {
  const [events, setEvents] = useState("");
  const [upcomingEvents, setUpcomingEvents] = useState("");
  const [posts, setPosts] = useState("");

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { data: eventdata } = useQuery(GET_EVENTS, {
    pollInterval: 500,
  });

  // Get All Events
  useEffect(() => {
    if (eventdata) {
      // console.log(eventdata.getEvents);
      setEvents(eventdata.getEvents);
    }
  }, [eventdata]);

  // Get Upcoming Events
  useEffect(() => {
    if (eventdata) {
      // console.log(eventdata.getEvents);
      const today = new Date(); // Get today's date
      const filteredEvents = eventdata.getEvents.filter(
        (event) => new Date(event.date) > today
      );
      // console.log("Upcoming", filteredEvents);
      setUpcomingEvents(filteredEvents);
    }
  }, [eventdata]);

  return (
    <PlainAdminHeader>
      <Box className="mt-16">
        <Box className="pt-5" display="flex" justifyContent="center">
          <Tabs value={value} onChange={handleChange} className="">
            <Tab label="Manage Events" className="" />
            <Tab label="Create an Event" />
          </Tabs>
        </Box>
        {/* Content for the selected tab */}
        <Box className="py-8">
          {value === 0 && <EventTable data={events} />}
          {value === 1 && <AddEvent />}
        </Box>
      </Box>
    </PlainAdminHeader>
  );
};

export default Event;
