import React from "react";
import { Box, Tabs, Tab, Fab } from "@mui/material";
import { useQuery } from "@apollo/client";
import PlainAdminHeader from "./UI/PlainAdminHeader";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { GET_MINOR_ADMINS } from "../../graphql/admin-queries";
import AdminTable from "./UsersAdmin/AdminTable";
import { useAuth } from "../../context/app.context";
// import { card1, card2 } from "./UI/GetStats";

const Admin = () => {
  let navigate = useNavigate();

  const { adminData } = useAuth();

  const [admins, setAdmins] = useState("");

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { data: adminsdata } = useQuery(GET_MINOR_ADMINS, {
    pollInterval: 500,
  });

  // Get All Events
  useEffect(() => {
    if (adminsdata) {
      // console.log(adminsdata.getMinorAdmins);
      setAdmins(adminsdata.getMinorAdmins);
    }
  }, [adminsdata]);

  // console.log(adminData.role, "role");

  return (
    <PlainAdminHeader>
      <Box className="mt-16">
        <Box
          className={`pt-5 md:w-[95%] lg:w-[93%] justify-center md:justify-end`}
          display="flex"
        >
          <Fab
            variant="extended"
            size="small"
            color="secondary"
            className={`${
              adminData.role !== "superadmin" ? "!hidden" : ""
            } !text-white`}
            onClick={() => navigate("/admin-signup")}
          >
            <Add sx={{ mr: 1 }} />
            Create Admin
          </Fab>
        </Box>
        <Box className="" display="flex" justifyContent="center">
          <Tabs value={value} onChange={handleChange} className="">
            <Tab label="Manage Admins" className="" />
            {/* <Tab label="Create an Event" /> */}
          </Tabs>
        </Box>
        {/* Content for the selected tab */}
        <Box className="py-8">
          {value === 0 && <AdminTable data={admins} />}
          {/* {value === 1 && <AddEvent />} */}
        </Box>
      </Box>
    </PlainAdminHeader>
  );
};

export default Admin;
