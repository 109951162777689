import React from "react";
import { Box, Tabs, Tab, Fab } from "@mui/material";
import { useQuery } from "@apollo/client";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import PartnersTable from "./Partners/PartnersTable";
import { useAuth } from "../../context/app.context";
import { GET_PARTNERS } from "../../graphql/admin-queries";
import PlainAdminHeader from "./UI/PlainAdminHeader";
import CreatePartner from "./Partners/CreatePartner";
import AddPartnersMedia from "./Partners/AddPartnersMedia";
import { GET_GALLERY, GET_PARTNERS_GALLERY } from "../../graphql/other-queries";
import AddPartnerVideo from "./Partners/AddPartnerVideo";
import PartnersMediaTable from "./Partners/PartnersMediaTable";
import { filterArrayDataByNotTypes } from "../../static/static";

// import { card1, card2 } from "./UI/GetStats";

const PartnersMedia = () => {
  let navigate = useNavigate();

  const { adminData } = useAuth();

  const [partners, setPartners] = useState("");

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { data } = useQuery(GET_GALLERY, {
    pollInterval: 500,
  });

  // const fileteredDataz = filterDataByNotType(data.getGallery, "gallery");

  const targetTypes = ["gallery", "reprimanded", "weekly-show"];
  const fileteredDataz = data?.getGallery
    ? filterArrayDataByNotTypes(data.getGallery, targetTypes)
    : [];

  // Get All Events
  useEffect(() => {
    if (data) {
      // console.log(data.getMinorAdmins);
      setPartners(fileteredDataz);
    }
  }, [data, fileteredDataz]);

  // console.log(adminData.role, "role");

  return (
    <PlainAdminHeader>
      <Box className="mt-16">
        <Box
          className={`pt-5 md:w-[95%] lg:w-[93%] justify-center md:justify-end`}
          display="flex"
        ></Box>
        <Box className="" display="flex" justifyContent="center">
          <Tabs value={value} onChange={handleChange} className="">
            <Tab label="Manage Partners Media" className="" />
            <Tab label="Add Partner Media" />
            <Tab label="Add Partner Video" />
          </Tabs>
        </Box>
        {/* Content for the selected tab */}
        <Box className="py-8">
          {value === 0 && <PartnersMediaTable data={partners} />}
          {value === 1 && <AddPartnersMedia />}
          {value === 2 && <AddPartnerVideo />}
        </Box>
      </Box>
    </PlainAdminHeader>
  );
};

export default PartnersMedia;
