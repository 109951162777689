import { gql } from "@apollo/client";

// Get all POSTS
export const GET_POSTS = gql`
  query Post {
    getPosts {
      id
      title
      content
      contentpic
      author
      category
      slug
      createdAt
      comments {
        id
        userId
        postId
        firstname
        lastname
        content
        contentpic
        avatar
        createdAt
        reactions {
          category
          categoryId
          type
          createdAt
        }
      }
      reactions {
        category
        categoryId
        type
        createdAt
      }
    }
  }
`;

// Get POST With Total Interactions
export const GET_POST_WITH_INTERACTIONS = gql`
  query Post {
    getPostWithInteractions {
      id
      title
      content
      contentpic
      author
      category
      totalInteractions
      slug
      createdAt
      comments {
        id
        userId
        postId
        firstname
        lastname
        content
        contentpic
        avatar
        createdAt
        reactions {
          category
          categoryId
          type
          createdAt
        }
      }
      reactions {
        category
        categoryId
        type
        createdAt
      }
    }
  }
`;

// Get FORUM INTERACTIONS
// export const GET_FORUM_INTERACTIONS = gql`
//   query Post {
//     getForumInteractions {
//     }
//   }
// `;

// Get a single POST
export const GET_POST = gql`
  query Post($id: ID!) {
    getPostById(id: $id) {
      id
      title
      content
      contentpic
      author
      category
      slug
      createdAt
      comments {
        id
        userId
        postId
        firstname
        lastname
        content
        contentpic
        avatar
        createdAt
        reactions {
          category
          categoryId
          type
          createdAt
        }
      }
      reactions {
        category
        categoryId
        type
        createdAt
      }
    }
  }
`;

// Get all ARTICLES
export const GET_ARTICLES = gql`
  query Articles {
    getArticles {
      id
      title
      content
      contentpic
      author
      category
      slug
      email
      social
      status
      createdAt
      artcomments {
        id
        name
        content
        articleId
        createdAt
      }
    }
  }
`;

// Get all ARTICLES
export const GET_PENDING_ARTICLES = gql`
  query Articles {
    getPendingArticles {
      id
      title
      content
      contentpic
      author
      category
      slug
      email
      social
      status
      createdAt
      artcomments {
        id
        name
        content
        articleId
        createdAt
      }
    }
  }
`;

// Get A SINGLE ARTICLE
export const GET_ARTICLE = gql`
  query Articles($id: ID!) {
    getArticlesById(id: $id) {
      id
      title
      content
      contentpic
      author
      category
      slug
      email
      social
      status
      createdAt
      artcomments {
        id
        name
        content
        articleId
        createdAt
      }
    }
  }
`;

export const GET_ALL_BIBLE_BOOKS = gql`
  query GetAllBibleBooks {
    getBibleBooks {
      book_name
      book
    }
  }
`;

export const GET_VERSE = gql`
  query GetVerse($book_name: String!, $chapter: Int!, $verse: Int!) {
    getBibleVerse(book_name: $book_name, chapter: $chapter, verse: $verse) {
      book_name
      chapter
      verse
      text
    }
  }
`;

// View POST_REACTIONS by id
export const GET_POST_REACTIONS = gql`
  query Reaction($categoryId: ID!) {
    getPostReaction(categoryId: $categoryId) {
      category
      categoryId
      type
      userId
      createdAt
    }
  }
`;

// View COMMENT_REACTIONS by id
export const GET_COMMENT_REACTIONS = gql`
  query Reaction($categoryId: ID!) {
    getCommentReaction(categoryId: $categoryId) {
      category
      categoryId
      type
      userId
      createdAt
    }
  }
`;

// View COMMENT_REACTIONS by id
export const GET_USER_REACTIONS = gql`
  query Reaction($userId: ID!) {
    getUserReactions(userId: $userId) {
      category
      categoryId
      type
      userId
      createdAt
    }
  }
`;

// View  COMMENT_REACTIONS by id
export const GET_USER_ARTICLES = gql`
  query Article($email: String!) {
    getUserArticles(email: $email) {
      id
      email
      title
      author
      category
      social
      status
      slug
      content
      contentpic
      createdAt
    }
  }
`;

// View  COMMENT_REACTIONS by id
export const GET_USER_COMMENTS = gql`
  query Comment($userId: ID!) {
    getUserComments(userId: $userId) {
      userId
      postId
      firstname
      lastname
      content
      contentpic
      avatar
      createdAt
    }
  }
`;

// View  Personnels
export const GET_PERSONNELS = gql`
  query Personnel {
    getPersonnels {
      id
      firstname
      lastname
      avatar
      dob
      social
      category
      bio
      createdAt
    }
  }
`;

// View One Personnel
export const GET_PERSONNEL = gql`
  query Personnel($id: ID!) {
    getPersonnelById(id: $id) {
      id
      firstname
      lastname
      avatar
      dob
      social
      category
      bio
      createdAt
    }
  }
`;

// View  GET_GALLERY
export const GET_GALLERY = gql`
  query Gallery {
    getGallery {
      id
      title
      imgSrc
      description
      type
      createdAt
    }
  }
`;

// View GET_SUBSCRIBER
export const GET_SUBSCRIBER = gql`
  query Subscriber {
    getSubscriber {
      email
      createdAt
    }
  }
`;

// View  GET_VISITORS
export const GET_VISITORS = gql`
  query Visitors {
    getVisitors {
      id
      ipaddress
      createdAt
    }
  }
`;

// View An Events
export const GET_EVENT = gql`
  query Event($id: ID!) {
    getEventById(id: $id) {
      id
      title
      date
      content
      imgSrc
      venue
      slug
    }
  }
`;

// View  Events
export const GET_EVENTS = gql`
  query Event {
    getEvents {
      id
      title
      date
      content
      imgSrc
      venue
      slug
    }
  }
`;

// Login User by email and password
export const SEND_NEWSLETTER = gql`
  query User($link: String!, $subject: String!, $message: String!) {
    sendNewsLetter(link: $link, subject: $subject, message: $message) {
      token
    }
  }
`;

// MUTATIONS

// Add Article
export const ADD_ARTICLE = gql`
  mutation AddArticleMutation(
    $title: String!
    $category: String!
    $author: String!
    $content: String!
    $contentpic: String!
    $email: String!
    $social: String
    $status: String!
  ) {
    addArticle(
      content: $content
      contentpic: $contentpic
      title: $title
      category: $category
      author: $author
      email: $email
      social: $social
      status: $status
    ) {
      title
      author
      email
      category
      content
      contentpic
      social
      status
      createdAt
    }
  }
`;

// Add Article
export const UPDATE_ARTICLE = gql`
  mutation UpdateArticleMutation($id: ID!, $title: String!, $content: String!) {
    updateArticle(id: $id, content: $content, title: $title) {
      title
      author
      email
      category
      content
      contentpic
      social
      status
      createdAt
    }
  }
`;

// Add Article
export const VERIFY_ARTICLE = gql`
  mutation VerifyArticleMutation($id: ID!) {
    verifyArticle(id: $id) {
      title
      author
      email
      category
      content
      contentpic
      social
      status
      createdAt
    }
  }
`;

// Add POST
export const ADD_POST = gql`
  mutation AddPostMutation(
    $title: String!
    $category: String!
    $author: String!
    $content: String!
    $contentpic: Upload!
  ) {
    addPost(
      content: $content
      contentpic: $contentpic
      title: $title
      category: $category
      author: $author
    ) {
      title
      author
      category
      content
      contentpic
      createdAt
    }
  }
`;

// Add POST
export const UPDATE_POST = gql`
  mutation UpdatePostMutation($id: ID!, $title: String!, $content: String!) {
    updatePost(id: $id, content: $content, title: $title) {
      title
      author
      category
      content
      contentpic
      createdAt
    }
  }
`;

// Add Event
export const ADD_EVENT = gql`
  mutation AddEventMutation(
    $title: String!
    $imgSrc: Upload!
    $venue: String!
    $content: String!
    $date: Date!
  ) {
    addEvent(
      content: $content
      date: $date
      title: $title
      imgSrc: $imgSrc
      venue: $venue
    ) {
      title
      venue
      imgSrc
      content
      date
      createdAt
    }
  }
`;

// Update Event
export const UPDATE_EVENT = gql`
  mutation AddEventMutation(
    $id: ID!
    $title: String!
    $imgSrc: String!
    $venue: String!
    $content: String!
    $date: Date!
  ) {
    updateEvent(
      id: $id
      content: $content
      date: $date
      title: $title
      imgSrc: $imgSrc
      venue: $venue
    ) {
      id
      title
      venue
      imgSrc
      content
      date
      createdAt
    }
  }
`;

// Add Post Reactions
export const ADD_ARTICLE_COMMENTS = gql`
  mutation AddArticleCommentMutation(
    $articleId: ID!
    $name: String!
    $content: String!
  ) {
    addArtComment(name: $name, articleId: $articleId, content: $content) {
      articleId
      name
      content
      createdAt
    }
  }
`;

// Add Comment to Post Comments
export const ADD_COMMENT = gql`
  mutation AddCommentMutation(
    $firstname: String!
    $lastname: String!
    $avatar: String!
    $content: String!
    $contentpic: String!
    $userId: ID!
    $postId: ID!
  ) {
    addComment(
      content: $content
      contentpic: $contentpic
      firstname: $firstname
      lastname: $lastname
      avatar: $avatar
      userId: $userId
      postId: $postId
    ) {
      userId
      postId
      firstname
      lastname
      content
      contentpic
      avatar
      createdAt
    }
  }
`;

// Add Post Reactions
export const ADD_POST_REACTION = gql`
  mutation AddPostReactionMutation(
    $type: String!
    $userId: ID!
    $categoryId: ID!
  ) {
    addPostReaction(type: $type, userId: $userId, categoryId: $categoryId) {
      userId
      categoryId
      type
      createdAt
    }
  }
`;

// Add Comment Reactions
export const ADD_COMMENT_REACTION = gql`
  mutation AddCommentReactionMutation(
    $type: String!
    $userId: ID!
    $categoryId: ID!
  ) {
    addCommentReaction(type: $type, userId: $userId, categoryId: $categoryId) {
      userId
      categoryId
      type
      createdAt
    }
  }
`;

// Add Gallery
export const ADD_GALLERY = gql`
  mutation AddGalleryMutation(
    $title: String!
    $imgSrc: Upload!
    $description: String!
    $type: String!
  ) {
    addGallery(
      title: $title
      imgSrc: $imgSrc
      description: $description
      type: $type
    ) {
      id
      title
      imgSrc
      description
      type
      createdAt
    }
  }
`;

// Add Gallery
export const ADD_PODCAST = gql`
  mutation AddPodcastMutation(
    $title: String!
    $imgSrc: String!
    $description: String!
    $type: String!
  ) {
    addPodcast(
      title: $title
      imgSrc: $imgSrc
      description: $description
      type: $type
    ) {
      id
      title
      imgSrc
      description
      type
      createdAt
    }
  }
`;

// Add Page Visitors IP Address
export const ADD_VISITOR = gql`
  mutation AddVisitorMutation($ipaddress: String!) {
    addVisitor(ipaddress: $ipaddress) {
      id
      ipaddress
      createdAt
    }
  }
`;

// Add Article
export const ADD_PERSONNEL = gql`
  mutation AddPersonnelMutation(
    $firstname: String!
    $lastname: String!
    $category: String!
    $bio: String!
    $avatar: Upload!
    $dob: Date!
    $social: String
  ) {
    addPersonnel(
      bio: $bio
      avatar: $avatar
      firstname: $firstname
      category: $category
      lastname: $lastname
      dob: $dob
      social: $social
    ) {
      firstname
      lastname
      dob
      category
      bio
      avatar
      social
      createdAt
    }
  }
`;

// Add Article
export const UPDATE_PERSONNEL = gql`
  mutation UpdatePersonnelMutation(
    $id: ID!
    $firstname: String!
    $lastname: String!
    $bio: String!
    $avatar: String!
    $dob: Date!
    $social: String
  ) {
    updatePersonnel(
      id: $id
      bio: $bio
      avatar: $avatar
      firstname: $firstname
      lastname: $lastname
      dob: $dob
      social: $social
    ) {
      id
      firstname
      lastname
      dob
      bio
      avatar
      social
      createdAt
    }
  }
`;

// Delete MUTATIONS

export const DELETE_GALLERY = gql`
  mutation DeleteGallery($id: ID!) {
    deleteGallery(id: $id) {
      id
    }
  }
`;

export const DELETE_PERSONNEL = gql`
  mutation DeletePersonnel($id: ID!) {
    deletePersonnel(id: $id) {
      id
    }
  }
`;

export const DELETE_EVENT = gql`
  mutation DeleteEvent($id: ID!) {
    deleteEvent(id: $id) {
      title
      venue
      imgSrc
      content
      date
      createdAt
    }
  }
`;

export const DELETE_ARTICLE = gql`
  mutation DeleteArticle($id: ID!) {
    deleteArticle(id: $id) {
      title
      author
      email
      category
      content
      contentpic
      social
      status
      createdAt
    }
  }
`;

export const DELETE_POST = gql`
  mutation DeletePost($id: ID!) {
    deletePost(id: $id) {
      id
    }
  }
`;
