// src/Routes.js
import React from "react";
import SampleApp from "../theme/mui/sample";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAuth } from "../context/app.context";
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import TokenSessionChecker from "../custom/Layout/TokenSessionChecker";
import PageLoader from "../custom/Layout/PageLoader";
import { LoaderContext } from "../context/loader.context";
import { useContext } from "react";
import ForgotPassword from "../pages/auth/ForgotPassword";
import NewPassword from "../pages/auth/NewPassword";
import Dashboard from "../pages/admin/Dashboard";
import Event from "../pages/admin/Event";
import UpdateEvent from "../pages/admin/Events/UpdateEvent";
import Article from "../pages/admin/Article";
import User from "../pages/admin/User";
import ViewArticle from "../pages/admin/Articles/ViewArticle";
import UpdateArticle from "../pages/admin/Articles/UpdateArticle";
import Admin from "../pages/admin/Admin";
import Personnels from "../pages/admin/Personnels";
import ViewPersonnel from "../pages/admin/Members/ViewPersonnel";
import UpdatePersonnel from "../pages/admin/Members/UpdatePersonnel";
import Gallery from "../pages/admin/Gallery";
import Forum from "../pages/admin/Forum";
import ViewPost from "../pages/admin/Forum/ViewPost";
import UpdatePost from "../pages/admin/Forum/UpdatePost";
import NewsLetter from "../pages/admin/NewsLetter";
import Partners from "../pages/admin/Partners";
import PartnersMedia from "../pages/admin/PartnersMedia";
import { PageError } from "../pages/PageError";

const ProtectedRoute = ({ element, ...rest }) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return element;
  } else {
    return <Navigate to="/signin" replace />;
  }
};

const SemiProtectedRoute = ({ element, ...rest }) => {
  const { adminData } = useAuth();
  const deniedRoles = ["editor", ""];
  // console.log("Admin Role:", adminData.role); // Check the role value

  if (deniedRoles.includes(adminData.role)) {
    // console.log(adminData, "Semi Admin Data");
    return <Navigate to="/signin" replace />;
  } else {
    // console.log(adminData.role, "Admin Role");
    return element;
  }
};

const SuperProtectedRoute = ({ element, ...rest }) => {
  const { adminData } = useAuth();
  const deniedRoles = ["admin", "editor", ""];
  // console.log("Admin Role:", adminData.role); // Check the role value

  if (deniedRoles.includes(adminData.role)) {
    // console.log(adminData, "Super Admin Data");
    return <Navigate to="/signin" replace />;
  } else {
    // console.log(adminData.role, "Admin Role");
    return element;
  }
};

const Navigator = () => {
  const loaderContext = useContext(LoaderContext);
  return (
    <React.Fragment>
      <Routes>
        {/* <Route path="/sample" element={<SampleApp />} /> */}

        <Route path="/signin" element={<SignIn />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/resetpassword/:slug" element={<NewPassword />} />

        <Route path="/" element={<ProtectedRoute element={<Dashboard />} />} />
        <Route
          path="/gallery"
          element={<ProtectedRoute element={<Gallery />} />}
        />
        <Route
          path="/newsletter"
          element={<ProtectedRoute element={<NewsLetter />} />}
        />

        <Route
          path="/events"
          element={<ProtectedRoute element={<Event />} />}
        />
        <Route
          path="/events/edit/:id"
          element={<ProtectedRoute element={<UpdateEvent />} />}
        />
        <Route
          path="/articles"
          element={<ProtectedRoute element={<Article />} />}
        />
        <Route
          path="/articles/:id"
          element={<ProtectedRoute element={<ViewArticle />} />}
        />
        <Route
          path="/articles/edit/:id"
          element={<ProtectedRoute element={<UpdateArticle />} />}
        />
        <Route
          path="/community"
          element={<ProtectedRoute element={<Forum />} />}
        />
        <Route
          path="/community/:id"
          element={<ProtectedRoute element={<ViewPost />} />}
        />
        <Route
          path="/community/edit/:id"
          element={<ProtectedRoute element={<UpdatePost />} />}
        />

        {/* For Only admin and superadmin */}

        <Route
          path="/personnels"
          element={<SemiProtectedRoute element={<Personnels />} />}
        />
        <Route
          path="/personnels/:id"
          element={<SemiProtectedRoute element={<ViewPersonnel />} />}
        />
        <Route
          path="/personnels/edit/:id"
          element={<SemiProtectedRoute element={<UpdatePersonnel />} />}
        />

        <Route
          path="/admins"
          element={<SemiProtectedRoute element={<Admin />} />}
        />

        <Route
          path="/crc-partners"
          element={<SemiProtectedRoute element={<Partners />} />}
        />
        <Route
          path="/crc-partners-media"
          element={<SemiProtectedRoute element={<PartnersMedia />} />}
        />

        <Route
          path="/users"
          element={<SemiProtectedRoute element={<User />} />}
        />

        {/* For Only  superadmin */}

        <Route
          path="/admin-signup"
          element={<SuperProtectedRoute element={<SignUp />} />}
        />

        <Route path="*" element={<PageError />} />

        {/* <Route component={ErrorPage} /> */}
      </Routes>
      <TokenSessionChecker />
      {loaderContext.showState && <PageLoader />}
    </React.Fragment>
  );
};

export default Navigator;
