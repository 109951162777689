import React from "react";
import { Box, Tabs, Tab } from "@mui/material";
import { useQuery } from "@apollo/client";
import PlainAdminHeader from "./UI/PlainAdminHeader";
import { GET_PERSONNELS } from "../../graphql/other-queries";
import { useState } from "react";
import { useEffect } from "react";
import PersonnelTable from "./Members/PersonnelTable";
import AddPersonnel from "./Members/AddPersonnel";
// import { card1, card2 } from "./UI/GetStats";

const Personnels = () => {
  const [members, setMembers] = useState("");

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { data: personneldata } = useQuery(GET_PERSONNELS, {
    pollInterval: 500,
  });

  // Get All Personnels
  useEffect(() => {
    if (personneldata) {
      // console.log(personneldata.getPersonnels);
      setMembers(personneldata.getPersonnels);
    }
  }, [personneldata]);

  return (
    <PlainAdminHeader>
      <Box className="mt-16">
        <Box className="pt-5" display="flex" justifyContent="center">
          <Tabs value={value} onChange={handleChange} className="">
            <Tab label="Manage Personnels" className="" />
            <Tab label="Create a Personnel" />
          </Tabs>
        </Box>
        {/* Content for the selected tab */}
        <Box className="py-8">
          {value === 0 && <PersonnelTable data={members} />}
          {value === 1 && <AddPersonnel />}
        </Box>
      </Box>
    </PlainAdminHeader>
  );
};

export default Personnels;
