import { Box, IconButton, Typography, Alert } from "@mui/material";
import React from "react";
import { formatDay } from "../../../hook/date-format";
import { Link, useNavigate } from "react-router-dom";

import { Visibility, Delete, Edit } from "@mui/icons-material";
import { DELETE_ARTICLE } from "../../../graphql/other-queries";
import { useMutation } from "@apollo/client";
import { useState } from "react";
import ActionModal from "../../../custom/Layout/ActionModal";
import Pagination from "../../../custom/Pagination/Pagination";

const VerifiedArticleTable = ({ data }) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage, setPostsPerPage] = React.useState(10);

  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentData = data.slice(firstPostIndex, lastPostIndex);

  const [show, setShow] = useState(false);
  const [msg, setmsg] = useState("");
  const [ID, setID] = useState(0);
  let navigate = useNavigate();

  const [deleteArticle, { data: eventData }] = useMutation(DELETE_ARTICLE);

  const handleView = (articleID) => {
    // console.log("Edit ID:", articleID);
    navigate(`/articles/${articleID}`);
  };

  const handleEdit = (articleID) => {
    // articleID.preventDefault();
    // console.log("Edit ID:", articleID);
    navigate(`/articles/edit/${articleID}`);
  };

  const handleDel = async (e, articleID) => {
    e.preventDefault();
    console.log("Delete ID:", articleID);

    try {
      const result = await deleteArticle({
        variables: { id: articleID },
      });

      // Handle the result as needed, e.g., show a success message
      console.log("Article deleted successfully", result);
      setmsg("Article deleted successfully");
      setShow(false);
    } catch (error) {
      // Handle any errors
      console.error("Error deleting Article", error);
      setmsg("Error deleting Article");
    }
  };
  return (
    <React.Fragment>
      <div className="overflow-x-auto relative sm:rounded-lg px-2 md:px-6 lg:px-20 mx-auto mb-12">
        {msg === "Article deleted successfully" ? (
          <Alert variant="filled" severity="success">
            {msg}
          </Alert>
        ) : msg === "Error deleting Article" ? (
          <Alert variant="filled" severity="error">
            {msg}
          </Alert>
        ) : null}
        <br />

        <table className="w-full text-sm text-left px-12">
          <thead className=" bg-[#263238]">
            {/* bg-[#ab47bc] */}
            <tr>
              <th scope="col" className="py-3 px-6">
                <Typography
                  variant="subheader"
                  fontSize={{ xs: "15px", sm: "20px" }}
                  lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className=" uppercase"
                  color={"white"}
                >
                  Articles
                </Typography>
              </th>
              <th scope="col" className="py-3 px-6">
                <Typography
                  variant="subheader"
                  fontSize={{ xs: "15px", sm: "20px" }}
                  lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className=" uppercase"
                  color={"white"}
                >
                  Published
                </Typography>
              </th>
              <th scope="col" className="py-3 px-6">
                <Typography
                  variant="subheader"
                  fontSize={{ xs: "15px", sm: "20px" }}
                  lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className=" uppercase"
                  color={"white"}
                >
                  Status
                </Typography>
              </th>
              <th scope="col" className="py-3 px-6 text-center">
                <Typography
                  variant="subheader"
                  fontSize={{ xs: "15px", sm: "20px" }}
                  lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className=" uppercase"
                  color={"white"}
                >
                  Action
                </Typography>
              </th>
            </tr>
          </thead>
          {currentData &&
            currentData.map((article, index) => (
              <>
                <tbody key={index}>
                  <tr className="bg-white border-b border-2 text-primary hover:bg-gray-200">
                    <th scope="row" className="py-4 px-6">
                      {/* <Link
      to={`${
        article.status === "verified"
          ? `/blog/${article.slug}/${article.id}`
          : "/profile"
      } `}
      className=" !no-underline sidebar-glide"
    > */}
                      <Typography
                        variant="caption"
                        fontSize={{ xs: "13px", sm: "16px", md: "20px" }}
                        lineHeight={{ xs: "200%", sm: "180%" }}
                        letterSpacing={"0.02em"}
                        className="text-start"
                      >
                        {article.title}
                      </Typography>
                      {/* </Link> */}
                    </th>
                    <td className="py-4 px-6">
                      <Typography
                        variant="caption"
                        fontSize={{ xs: "12px", sm: "13px", md: "16px" }}
                        lineHeight={{ xs: "200%", sm: "180%" }}
                        letterSpacing={"0.02em"}
                        className="text-start opacity-70"
                      >
                        {formatDay(article.createdAt)}
                      </Typography>
                    </td>
                    <td className="py-4 px-6">
                      <Typography
                        variant="caption"
                        fontSize={{ xs: "12px", sm: "13px", md: "16px" }}
                        lineHeight={{ xs: "200%", sm: "180%" }}
                        letterSpacing={"0.02em"}
                        className="text-start opacity-70"
                        color="green"
                      >
                        {article.status}
                      </Typography>
                    </td>
                    <td className="py-4 px-6">
                      {/* <Typography
      variant="caption"
      fontSize={{ xs: "12px", sm: "13px", md: "16px" }}
      lineHeight={{ xs: "200%", sm: "180%" }}
      letterSpacing={"0.02em"}
      className={`"text-start opacity-70" `}
    >
      {article.status}
    </Typography> */}
                      <Box className="flex gap-2 items-center place-content-center !text-center">
                        <IconButton
                          aria-label="view"
                          onClick={() => {
                            handleView(article.id);
                          }}
                          color="primary"
                        >
                          <Visibility />
                        </IconButton>

                        <IconButton
                          aria-label="edit"
                          onClick={() => {
                            handleEdit(article.id);
                          }}
                          color="primary"
                        >
                          <Edit />
                        </IconButton>

                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            // handleDel(article.id);
                            setID(article.id);
                            setShow(true);
                          }}
                          color="primary"
                        >
                          <Delete />
                        </IconButton>
                      </Box>
                    </td>
                  </tr>
                </tbody>
              </>
            ))}
        </table>

        <div className=" mx-auto">
          <Pagination
            totalPosts={data.length}
            postsPerPage={postsPerPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </div>

        {show && (
          <ActionModal
            statement={"Do you wish to delete Article?"}
            label1={"Delete"}
            label2={"Cancel"}
            modalstate={show}
            closeModal={() => setShow(false)}
            handleDelete={(e) => handleDel(e, ID)}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default VerifiedArticleTable;
