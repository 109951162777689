import AcUnitIcon from "@mui/icons-material/AcUnit";
import VerifiedIcon from "@mui/icons-material/Verified";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";

import AccountCircleIcon from "@mui/icons-material/AccountCircle"; // Import an icon for the "Profile" route, you can change this to your preferred icon
import SettingsIcon from "@mui/icons-material/Settings"; // Import an icon for the "Account" route
import DashboardIcon from "@mui/icons-material/Dashboard"; // Import an icon for the "Dashboard" route
import ExitToAppIcon from "@mui/icons-material/ExitToApp"; // Import an icon for the "Logout" route
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import CelebrationOutlinedIcon from "@mui/icons-material/CelebrationOutlined";
import Theaters from "@mui/icons-material/Theaters";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";

// You can use these icons in your components as needed.

export const adminPagesIcon = [
  {
    name: "Dashboard",
    path: "",
    icon: <DashboardIcon className="!text-xl" />,
  },
  {
    name: "Community",
    path: "community",
    icon: <ForumOutlinedIcon className="!text-xl" />,
  },
  {
    name: "Articles",
    path: "articles",
    icon: <ArticleOutlinedIcon className="!text-xl" />,
  },
  {
    name: "Events",
    path: "events",
    icon: <EventOutlinedIcon className="!text-xl" />,
  },
  {
    name: "Gallery",
    path: "gallery",
    icon: <Theaters className="!text-xl" />,
  },
  {
    name: "Partners Gallery",
    path: "crc-partners-media",
    icon: <Theaters className="!text-xl" />,
  },

  {
    name: "Personnels",
    path: "personnels",
    icon: <CelebrationOutlinedIcon className="!text-xl" />,
  },
  {
    name: "CRC-Partners",
    path: "crc-partners",
    icon: <HandshakeOutlinedIcon className="!text-xl" />,
  },
  {
    name: "User",
    path: "users",
    icon: <AccountCircleIcon className="!text-xl" />,
  },
  {
    name: "Admin",
    path: "admins",
    icon: <AssignmentIndIcon className="!text-xl" />,
  },

  {
    name: "Newsletter",
    path: "newsletter",
    icon: <VerifiedIcon className="!text-xl" />,
  },
  {
    name: "Logout",
    path: "",
    icon: <ExitToAppIcon className="!text-xl" />,
  },
];

export const adminEditorPagesIcon = [
  {
    name: "Dashboard",
    path: "",
    icon: <DashboardIcon className="!text-xl" />,
  },
  {
    name: "Community",
    path: "community",
    icon: <ForumOutlinedIcon className="!text-xl" />,
  },
  {
    name: "Articles",
    path: "articles",
    icon: <ArticleOutlinedIcon className="!text-xl" />,
  },
  {
    name: "Events",
    path: "events",
    icon: <EventOutlinedIcon className="!text-xl" />,
  },
  {
    name: "Gallery",
    path: "gallery",
    icon: <Theaters className="!text-xl" />,
  },

  {
    name: "Newsletter",
    path: "newsletter",
    icon: <VerifiedIcon className="!text-xl" />,
  },
  {
    name: "Logout",
    path: "",
    icon: <ExitToAppIcon className="!text-xl" />,
  },
];

export const footerMenus = [
  {
    name: "useful links",
    items: [
      { name: "Home", path: "/" },
      { name: "About Us", path: "/about" },
      {
        name: "Contact Us",
        path: "/contact",
      },
    ],
  },
  {
    name: "other resources",
    items: [
      {
        name: "Events",
        path: "events",
      },

      { name: "Article", path: "/article" },
      {
        name: "Community",
        path: "community",
      },
    ],
  },
];

export const footerSocials = [
  {
    color: "#1877F2",
    bgColor: "#fff",
    name: "facebook",
    icon: <FacebookOutlinedIcon color="#1877F2" />,
    path: "https://www.facebook.com/profile.php?id=100066679125437&mibextid=2JQ9oc",
  },
  {
    color: "#d62976",
    bgColor: "#fff",
    name: "instagram",
    icon: <InstagramIcon color="#d62976" />,
    path: "https://instagram.com/celestialrealitycheck?igshid=MzRlODBiNWFlZA==",
  },
];

export const personnelCategory = [
  "crc-member",
  "crc-media",
  "ccc-evangelist",
  "ccc-artiste",
];

export function filterDataByNotType(data, targetType) {
  // Check if data is an array or can be converted to an array
  if (!Array.isArray(data)) {
    console.error("Input data is not an array.");
    return [];
  }

  // Use the filter method to filter the data
  const filteredData = data.filter((item) => item.type !== targetType);
  return filteredData;
}

export function filterDataByType(data, targetType) {
  // Check if data is an array or can be converted to an array
  if (!Array.isArray(data)) {
    console.error("Input data is not an array.");
    return [];
  }

  // Use the filter method to filter the data by the targetType
  const filteredData = data.filter((item) => item.type === targetType);
  return filteredData;
}

export function filterArrayDataByNotTypes(data, targetTypes) {
  // Check if data is an array or can be converted to an array
  if (!Array.isArray(data)) {
    console.error("Input data is not an array.");
    return [];
  }

  // Use the filter method to filter the data by the target types
  const filteredData = data.filter((item) => !targetTypes.includes(item.type));
  return filteredData;
}

export function filterArrayDataByTypes(data, targetTypes) {
  // Check if data is an array or can be converted to an array
  if (!Array.isArray(data)) {
    console.error("Input data is not an array.");
    return [];
  }

  // Use the filter method to filter the data by the target types
  const filteredData = data.filter((item) => targetTypes.includes(item.type));
  return filteredData;
}
