import { createTheme } from "@mui/material/styles";
import { red, common, green, orange, purple } from "@mui/material/colors";
import { buttonClasses } from "@mui/material/Button";

const primaryColor = "#263238";

export const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
      light: common.white,
    },
    secondary: {
      main: green[500],
      light: purple[400],
    },
    background: {
      default: "#F5F5F5",
      paper: "#F8F8F8",
      // paper: common.white
    },
    success: {
      main: green.A400,
    },
    error: {
      main: red[600],
    },
    warning: {
      main: orange[800],
    },
  },
  typography: {
    subtitle: {
      fontSize: 14,
      fontWeight: 300,
      color: primaryColor,
      fontFamily: "Bricolage Grotesque",
    },
    caption: {
      fontSize: "1rem",
      fontWeight: 300,
      fontStyle: "italic",
      color: primaryColor,
      fontFamily: "Bricolage Grotesque",
    },
    body: {
      fontSize: "1rem",
      fontWeight: 400,
      color: primaryColor,
      fontFamily: "Bricolage Grotesque",
    },

    subheader: {
      fontSize: "1.4rem",
      fontWeight: 600,
      color: primaryColor,
      fontFamily: "Playfair Display",
    },
    subItalic: {
      fontSize: "1.4rem",
      fontWeight: 600,
      fontStyle: "italic",
      color: primaryColor,
      fontFamily: "Playfair Display",
    },
    header: {
      fontSize: "1.8rem",
      fontWeight: 800,
      color: primaryColor,
      fontFamily: "Playfair Display",
    },
  },

  components: {
    MuiChip: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            // https://mui.com/system/getting-started/the-sx-prop/#spacing
            px: 1,
            py: 0.25,
            // https://mui.com/system/borders/#border-radius
            borderRadius: 1, // 4px as default.
          }),
        label: {
          padding: "initial",
          fontFamily: "Bricolage Grotesque",
        },
        icon: ({ theme }) =>
          theme.unstable_sx({
            mr: 0.5,
            ml: "-2px",
          }),
      },
    },

    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "0.8rem",
          fontFamily: "Bricolage Grotesque",
          borderRadius: 0,
          boxShadow: "none",
          textTransform: "none",
          padding: "6px 12px",
          letterSpacing: "0.05em",
          ":hover": {
            backgroundColor: green[500],
            color: "#fff",
          },
          ":active": {
            backgroundColor: green[500],
            color: primaryColor,
          },
          transition: "0.32s ease-out",
          ...{
            [`&.${buttonClasses.outlined}`]: {
              color: "#194049",
            },
          },
          ...{
            [`&.${buttonClasses.outlined}:hover`]: {
              backgroundColor: "#F1F1F1",
              borderColor: "#F1F1F1",
            },
          },
          ...{
            [`&.${buttonClasses.outlined}:active`]: {
              backgroundColor: "#F1F1F1",
              borderColor: "#F1F1F1",
            },
          },
          ...{
            [`&.${buttonClasses.outlinedSecondary}`]: {
              backgroundColor: green[500],
              borderColor: green[500],
              color: primaryColor,
            },
          },
          ...{
            [`&.${buttonClasses.outlinedSecondary}:hover`]: {
              backgroundColor: "transparent",
              color: primaryColor,
              borderColor: green[500],
            },
          },
          ...{
            [`&.${buttonClasses.outlinedSecondary}:active`]: {
              backgroundColor: "transparent",
              color: primaryColor,
              borderColor: green[500],
            },
          },
        },
      },
      defaultProps: {
        // disableRipple: true
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFFFFF",
          boxShadow: "0px 10px 16px 0px rgba(154, 154, 154, 0.13)",
        },
      },
      defaultProps: {
        square: true,
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          display: "flex",
          justifyContent: "center",
          marginTop: "20px",
        },
        ul: {
          listStyle: "none",
          padding: 0,
          display: "inline-flex",
          borderRadius: "4px",
          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#f5f5f5",
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          fontFamily: "Bricolage Grotesque", // Add Bricolage Grotesque font
          color: primaryColor,
          fontWeight: 600,
          fontSize: "20px",
          border: "none",
          backgroundColor: "transparent",
          transition: "background-color 0.3s, color 0.3s",
          "&:hover": {
            backgroundColor: green[500],
            color: "#fff",
          },
        },
        textPrimary: {
          "&.Mui-selected": {
            backgroundColor: green[500],
            color: "#fff",
          },
        },
      },
    },
  },
});
