import {
  Avatar,
  Box,
  Container,
  CssBaseline,
  IconButton,
  Typography,
  Grid,
} from "@mui/material";
import React from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import SignInForm from "./SignInForm";

const SignInCard = () => {
  return (
    <React.Fragment>
      <Container component="main" maxWidth="xl">
        <CssBaseline />
        <Grid
          container
          spacing={{ xs: 0, sm: 2, md: 2 }}
          className=" place-content-center"
        >
          <Grid item xs={12} sm={6} md={6}>
            <Box className="w-full h-full">
              <Box className="text-center">
                <Typography
                  variant="header"
                  fontSize={{ xs: "25px", sm: "30px" }}
                  lineHeight={{ xs: "120%", sm: "120%" }}
                  letterSpacing={"0.02em"}
                  className="mx-auto  capitalize text-center place-content-center"
                >
                  Manage Our Services
                </Typography>
                <br />
                <br />

                <Typography
                  variant="subtitle"
                  color="primary"
                  fontSize={{ xs: "18px", sm: "18px" }}
                  lineHeight={{ xs: "120%", sm: "120%" }}
                  letterSpacing={"0.02em"}
                  className=" opacity-70"
                >
                  Efficiently overseeing all services, ensuring smooth
                  operations, and fostering a welcoming community for meaningful
                  discussions and spiritual exploration.
                </Typography>
              </Box>
              <Box className="w-3/4 mx-auto mt-6">
                <img
                  src={`${process.env.REACT_APP_S3_BUCKET_URL}/designs/admin-auth.jpg`}
                  alt="Community"
                  className=" object-cover"
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box
              sx={{
                marginTop: 0,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar
                sx={{ bgcolor: "secondary.light", p: 3 }}
                className=" flex place-content-center mx-auto"
              >
                <IconButton
                  variant="gradient"
                  size="lg"
                  className="pointer-events-none mb-6 rounded-full "
                >
                  <LockOutlinedIcon className="text-[#fff]" />
                </IconButton>
              </Avatar>
              <Typography
                variant="subheader"
                className="mx-auto place-content-center flex pt-2 pb-8"
              >
                Sign In
              </Typography>

              <SignInForm />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default SignInCard;
