import React from "react";
import {
  IconButton,
  Typography,
  Container,
  Avatar,
  Grid,
  Divider,
  Box,
  CssBaseline,
} from "@mui/material";
import { footerMenus, footerSocials } from "../../static/static";
import { Link } from "react-router-dom";

function CRCFooter() {
  const year = new Date().getFullYear();

  return (
    <footer
      sx={{ backgroundColor: "primary.main", color: "common.white", py: 6 }}
    >
      <CssBaseline />
      <Box className="px-12 py-12">
        <Grid container spacing={4}>
          <Grid item xs={12} lg={6}>
            <Box className="flex">
              <Avatar
                alt="CRC"
                src={`${process.env.REACT_APP_S3_BUCKET_URL}/designs/crc512_r3usay.png`}
                style={{
                  marginRight: 5,
                  width: 60,
                  height: 60,
                }}
              />
              <Typography variant="header" gutterBottom>
                Celestial Reality Check
              </Typography>
            </Box>
            <Typography
              variant="body"
              fontSize={{ xs: "13px", sm: "13px" }}
              lineHeight={{ xs: "200%", sm: "180%" }}
              letterSpacing={"0.02em"}
              className=" opacity-60 w-full md:w-2/3"
              paragraph
            >
              championing the course for transparency and accountability for
              social development in Celestial Church of Christ across all levels
            </Typography>
            <Grid container justifyContent="start" spacing={2}>
              {footerSocials.map(({ color, name, path, icon }) => (
                <Grid item key={name}>
                  <a
                    href={path}
                    target="_blank"
                    rel="noopener noreferrer "
                    className="hover:cursor-pointer"
                  >
                    <IconButton sx={{ "&:hover": { color: color } }}>
                      {icon}
                    </IconButton>
                  </a>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container spacing={4}>
              {footerMenus.map(({ name, items }) => (
                <Grid item xs={6} key={name}>
                  <Typography
                    variant="subheader"
                    className="!text-xs !uppercase"
                    gutterBottom
                  >
                    {name}
                  </Typography>
                  <ul>
                    {items.map((item) => (
                      <li key={item.name} className="no-underline">
                        <Link
                          to={item.path}
                          variant="body2"
                          color="inherit"
                          className="!no-underline"
                        >
                          <Typography variant="subtitle">
                            {item.name}
                          </Typography>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={{ mt: 6 }} />
        <Box mt={4} textAlign="center">
          <Typography
            variant="subtitle"
            fontSize={{ xs: "13px", sm: "13px" }}
            lineHeight={{ xs: "200%", sm: "180%" }}
            letterSpacing={"0.02em"}
          >
            Copyright © {year} CRC by{" "}
            <a
              href="https://www.cyberbracetech.com"
              target="_blank"
              color="inherit"
              rel="noopener noreferrer"
              className="underline"
            >
              CyberBrace Tech
            </a>
            .
          </Typography>
        </Box>
      </Box>
    </footer>
  );
}

export default CRCFooter;
