import {
  Alert,
  Box,
  CircularProgress,
  FormGroup,
  Grid,
  TextField,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";

import CRCbutton from "../../../custom/Forms/CRCbutton";
import CRCFileUpload from "../../../custom/Forms/CRCFileUpload";
import { LoaderContext } from "../../../context/loader.context";
import { useMutation, useQuery } from "@apollo/client";
import { GET_POST, UPDATE_POST } from "../../../graphql/other-queries";
import CRCeditor from "../../../custom/Forms/CRCeditor";
import { formatDBdate, formatDay } from "../../../hook/date-format";
import PlainAdminHeader from "../UI/PlainAdminHeader";

const UpdatePost = () => {
  const loaderContext = useContext(LoaderContext);
  const [post, setPost] = useState("");
  let navigate = useNavigate();
  const params = useParams();

  const openLoader = () => {
    loaderContext.setShowState(true);
  };

  const closeLoader = () => {
    loaderContext.setShowState(false);
  };

  const { data: postData } = useQuery(GET_POST, {
    variables: { id: params.id },
    pollInterval: 500,
  });

  React.useEffect(() => {
    if (postData) {
      // console.log("EVENTS", postData.getPostById);
      //   Filter Articles by like
      const post = postData.getPostById;
      setPost(post);
    }
  }, [postData]);

  const [updatePost, { loading, error, data: updatePostData }] =
    useMutation(UPDATE_POST);
  const [msg, setMsg] = useState("");

  const [title, settitle] = useState("");
  const [imgSrc, setimgSrc] = useState("");
  const [venue, setvenue] = useState("");
  const [content, setcontent] = useState("");
  const [date, setdate] = useState("");

  const [formData, setFormData] = useState({
    id: 0,
    title: "",
    content: "",
  });

  React.useEffect(() => {
    setFormData({
      ...formData,
      id: post.id,
      title: title || post.title,
      content: content || post.content,
    });

    // console.log("formData: ", formData);
  }, [title, content, formData.content, postData, params.id]);

  const handleContent = (content) => {
    setFormData({ ...formData, content });
    setcontent(content);
    setMsg("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // openLoader();
    const emptyFields = Object.keys(formData).filter((key) => !formData[key]);

    if (emptyFields.length > 0) {
      // Display error alert for empty fields
      return;
    }

    // console.log("FormData", formData);
    try {
      const { data } = await updatePost({
        variables: formData,
      });

      // Handle successful form submission (if needed)
      console.log("Post Updated successfully:", data);
      closeLoader();
      setMsg("Post Updated successfully");
      setTimeout(() => {
        navigate(`/community`);
      }, 3000);
    } catch (error) {
      // Display error alert for server error
      console.error("Post Update error:", error.message);
    }
  };
  return (
    <React.Fragment>
      <PlainAdminHeader>
        <Box className="mt-16">
          <div className="overflow-x-hidden relative sm:rounded-lg px-2 md:px-6 lg:px-20 !mx-auto mb-12">
            <form onSubmit={handleSubmit}>
              <FormGroup>
                {loading && <CircularProgress />}{" "}
                {/* Display loading indicator */}
                {msg === "Post Updated successfully" ? (
                  <Alert variant="filled" severity="success">
                    {msg}
                  </Alert>
                ) : msg === "Please Select an Image" ? (
                  <Alert variant="filled" severity="error">
                    {msg}
                  </Alert>
                ) : null}
                <br />
                {/* Display error alert */}
                <Grid container spacing={{ xs: 0, md: 2 }}>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextField
                      fullWidth
                      variant="standard"
                      label=""
                      name="title"
                      type="name"
                      placeholder={post.title}
                      value={title || formData.title}
                      onChange={(e) => {
                        // setFormData({ ...formData, title: e.target.value });
                        settitle(e.target.value);
                        setMsg("");
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <CRCeditor
                      value={formData.content}
                      onChange={handleContent}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box className="mx-auto mt-8 w-[60%] md:w-[40%]">
                    <CRCbutton label={"Update Post"} type={"submit"} />
                  </Box>
                </Grid>
              </FormGroup>
            </form>
          </div>
        </Box>
      </PlainAdminHeader>
    </React.Fragment>
  );
};

export default UpdatePost;
