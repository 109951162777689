import {
  Alert,
  Box,
  CircularProgress,
  FormGroup,
  Grid,
  TextField,
} from "@mui/material";
import React, { useContext, useState } from "react";

import CRCbutton from "../../../custom/Forms/CRCbutton";
import CRCFileUpload from "../../../custom/Forms/CRCFileUpload";
import { useNavigate } from "react-router-dom";
import CRCTextField from "../../../custom/Forms/CRCTextField";
import { LoaderContext } from "../../../context/loader.context";
import { useMutation } from "@apollo/client";
import { ADD_PERSONNEL } from "../../../graphql/other-queries";
import { formatDBdate } from "../../../hook/date-format";
import CRCSelect from "../../../custom/Forms/CRCSelect";
import { personnelCategory } from "../../../static/static";

const AddPersonnel = () => {
  let navigate = useNavigate();
  const loaderContext = useContext(LoaderContext);

  const openLoader = () => {
    loaderContext.setShowState(true);
  };

  const closeLoader = () => {
    loaderContext.setShowState(false);
  };

  const [addPersonnel, { loading, error, data }] = useMutation(ADD_PERSONNEL);
  const [msg, setMsg] = useState("");
  // const name = userData.firstname + " " + userData.lastname;
  // console.log("Author: ", name);

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    avatar: "",
    category: "",
    dob: "",
    bio: "",
    social: "",
  });

  const [avatarPic, setAvatarPic] = useState("");

  const handleAvatarPicChange = (event) => {
    const selectedFile = event.target.files[0];
    setMsg("");

    if (selectedFile) {
      setFormData({ ...formData, avatar: selectedFile });
      setAvatarPic(selectedFile.name);
    }
  };

  const handleCategoryChange = (selectedCategory) => {
    setFormData({ ...formData, category: selectedCategory }); // Update the selected category in the form data
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // openLoader();
    const emptyFields = Object.keys(formData).filter((key) => !formData[key]);

    if (emptyFields.length > 0) {
      // Display error alert for empty fields
      setMsg("Please input all fields");
      return;
    }
    if (formData.avatar === "") {
      setMsg("Please Select an Image");
    }

    // console.log("FormData", formData);
    try {
      const { data } = await addPersonnel({
        variables: formData,
      });

      // Handle successful form submission (if needed)
      console.log("Member Created successfully:", data);
      closeLoader();
      setMsg("Member created successfully");
      setTimeout(() => {
        // navigate(`/personnels`);
        window.location.reload();
      }, 3000);
    } catch (error) {
      // Display error alert for server error
      console.error("Member Creating error:", error.message);
    }
  };
  return (
    <React.Fragment>
      <div className="overflow-x-hidden relative sm:rounded-lg px-2 md:px-6 lg:px-20 !mx-auto mb-12">
        <form onSubmit={handleSubmit}>
          <FormGroup>
            {loading && <CircularProgress />} {/* Display loading indicator */}
            {msg === "Member created successfully" ? (
              <Alert variant="filled" severity="success">
                {msg}
              </Alert>
            ) : msg === "Please input all fields" ? (
              <Alert variant="filled" severity="error">
                {msg}
              </Alert>
            ) : msg === "Please Select an Image" ? (
              <Alert variant="filled" severity="error">
                {msg}
              </Alert>
            ) : null}
            <br />
            {/* Display error alert */}
            <Grid container spacing={{ xs: 0, sm: 2, md: 2 }}>
              <Grid item xs={12} sm={6} md={6}>
                <CRCTextField
                  label="First Name"
                  name="First Name"
                  type="name"
                  onChange={(e) => {
                    setFormData({ ...formData, firstname: e.target.value });
                    setMsg("");
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CRCTextField
                  label="Last Name"
                  name="Last Name"
                  type="name"
                  onChange={(e) => {
                    setFormData({ ...formData, lastname: e.target.value });
                    setMsg("");
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={{ xs: 0, sm: 2, md: 2 }}>
              <Grid item xs={12} sm={6} md={6}>
                <CRCTextField
                  label="Facebook e.g: https://www.facebook.com/johndoe"
                  name="social"
                  type="name"
                  onChange={(e) => {
                    setFormData({ ...formData, social: e.target.value });
                    setMsg("");
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CRCSelect
                  label="Select category"
                  values={personnelCategory}
                  placeholder="Select category"
                  onChange={handleCategoryChange} // Pass the handler to update the selected country
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={{ xs: 0, sm: 2, md: 2 }}
              className="items-center py-3"
            >
              <Grid item xs={12} sm={6} className="!mt-4">
                <CRCTextField
                  label={formData.dob === "" ? "" : formData.dob}
                  name="dob"
                  type="date"
                  onChange={(e) => {
                    let date = formatDBdate(e.target.value);
                    setFormData({ ...formData, dob: date });
                    console.log("DOB", date);
                    setMsg("");
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6} className="">
                <CRCFileUpload
                  label="Select an image"
                  name="member image"
                  type="file"
                  value={avatarPic}
                  onChange={handleAvatarPicChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={{ xs: 0, md: 2 }} className="">
              <Grid item xs={12} sm={12}>
                <CRCTextField
                  label="Biography"
                  name="Biography"
                  multiline
                  type="name"
                  onChange={(e) => {
                    setFormData({ ...formData, bio: e.target.value });
                    setMsg("");
                  }}
                />
                {/* <CRCeditor value={formData.bio} onChange={handleBio} /> */}
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box className="mx-auto mt-8 w-[60%] md:w-[40%]">
                <CRCbutton label={"Add Member"} type={"submit"} />
              </Box>
            </Grid>
          </FormGroup>
        </form>
      </div>
    </React.Fragment>
  );
};

export default AddPersonnel;
