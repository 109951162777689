import React from "react";
import { Box, Tabs, Tab } from "@mui/material";
import { useQuery } from "@apollo/client";
import PlainAdminHeader from "./UI/PlainAdminHeader";
import { GET_GALLERY } from "../../graphql/other-queries";
import { useState } from "react";
import { useEffect } from "react";
import GalleryTable from "./Gallery/GalleryTable";
import AddGallery from "./Gallery/AddGallery";
import { filterArrayDataByTypes } from "../../static/static";
import AddGalleryVideo from "./Gallery/AddGalleryVideo";
// import { card1, card2 } from "./UI/GetStats";

const Gallery = () => {
  const [gallery, setGallery] = useState("");

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { data: gallerydata } = useQuery(GET_GALLERY, {
    pollInterval: 500,
  });

  const targetTypes = ["gallery", "reprimanded", "weekly-show"];
  const fileteredDataz = gallerydata?.getGallery
    ? filterArrayDataByTypes(gallerydata.getGallery, targetTypes)
    : [];

  // Get All Gallery
  useEffect(() => {
    if (gallerydata) {
      // console.log(gallerydata.getGallery);
      setGallery(fileteredDataz);
    }
  }, [gallerydata, fileteredDataz]);

  return (
    <PlainAdminHeader>
      <Box className="mt-16">
        <Box className="pt-5" display="flex" justifyContent="center">
          <Tabs value={value} onChange={handleChange} className="">
            <Tab label="Manage Gallery" className="" />
            <Tab label="Add Gallery" />
            <Tab label="Add Video Content" />
          </Tabs>
        </Box>
        {/* Content for the selected tab */}
        <Box className="py-8">
          {value === 0 && <GalleryTable data={gallery} />}
          {value === 1 && <AddGallery />}
          {value === 2 && <AddGalleryVideo />}
        </Box>
      </Box>
    </PlainAdminHeader>
  );
};

export default Gallery;
