import React, { useState } from "react";
import {
  Alert,
  Box,
  CircularProgress,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import CRCTextField from "../../custom/Forms/CRCTextField";
import CRCbutton from "../../custom/Forms/CRCbutton";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { LOGIN_ADMIN } from "../../graphql/admin-queries";
import { useAuth } from "../../context/app.context";
import { useContext } from "react";
import { LoaderContext } from "../../context/loader.context";
import PageLoader from "../../custom/Layout/PageLoader";

const SignInForm = () => {
  const { isAuthenticated, userData, loginAdmin } = useAuth();
  let navigate = useNavigate();

  const loaderContext = useContext(LoaderContext);

  const openLoader = () => {
    loaderContext.setShowState(true);
  };

  const closeLoader = () => {
    loaderContext.setShowState(false);
  };

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleInputChange = (fieldName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
    setSubmitClicked(false); // Reset submitClicked when input changes
  };

  const [submitClicked, setSubmitClicked] = useState(false);

  // Using the LOGIN_ADMIN query conditionally based on submitClicked
  const { loading, error, data } = useQuery(LOGIN_ADMIN, {
    variables: { email: formData.email, password: formData.password },
    skip: !submitClicked,
    onCompleted: (data) => {
      if (data && data.loginAdmin) {
        openLoader();
        const token = data.loginAdmin.token;
        closeLoader();
        loginAdmin(token);
      }
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const emptyFields = Object.keys(formData).filter((key) => !formData[key]);

    if (emptyFields.length > 0) {
      console.log("Empty fields:", emptyFields);
      return;
    }

    // console.log("Form data:", formData);

    if (loading) return; // No need to return JSX here
    setSubmitClicked(true);
  };

  React.useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  return (
    <form onSubmit={handleSubmit}>
      <FormGroup className="">
        {loading && <CircularProgress className="!text-8xl" />}
        {error && (
          <Alert variant="filled" severity="error" className="mt-4">
            {error.message === "Unexpected error."
              ? "Incorrect details"
              : error.message}
          </Alert>
        )}
        <Grid container spacing={{ xs: 0, sm: 2, md: 2 }} className="">
          <Grid item xs={12} md={12}>
            <CRCTextField
              label="Email"
              name="email"
              type="email"
              onChange={(e) => {
                // setFormData({ ...formData, email: e.target.value })
                handleInputChange("email", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <CRCTextField
              label="Password"
              name="password"
              type="password"
              onChange={(e) => {
                // setFormData({ ...formData, password: e.target.value })
                handleInputChange("password", e.target.value);
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} md={12}>
          <Box className="mx-auto mt-8">
            <CRCbutton label={"Submit"} type={"submit"} />
          </Box>
        </Grid>

        <Grid container spacing={{ xs: 0, md: 2 }} className=" flex px-2">
          <Grid item xs={6} md={6} className=" place-content-start flex">
            <Link to="/forgotpassword">
              <Typography variant="subtitle" color="initial">
                Forgot Password
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </FormGroup>
    </form>
  );
};

export default SignInForm;
