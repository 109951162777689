import { Typography } from "@mui/material";
import React from "react";

const AuthorsTable = ({ data }) => {
  return (
    <React.Fragment>
      <div className="overflow-x-auto relative sm:rounded-lg px-2 md:px-6 lg:px-20 mx-auto mb-12">
        <br />
        <table className="w-full text-sm text-left px-12">
          <thead className=" bg-[#263238]">
            {/* bg-[#ab47bc] */}
            <tr>
              <th scope="col" className="py-3 px-6">
                <Typography
                  variant="subheader"
                  fontSize={{ xs: "15px", sm: "20px" }}
                  lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className=" uppercase"
                  color={"white"}
                >
                  Authors
                </Typography>
              </th>
              <th scope="col" className="py-3 px-6">
                <Typography
                  variant="subheader"
                  fontSize={{ xs: "15px", sm: "20px" }}
                  lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className=" uppercase"
                  color={"white"}
                >
                  Emails
                </Typography>
              </th>
            </tr>
          </thead>
          {data &&
            data.map((author, index) => (
              <>
                <tbody key={index}>
                  <tr className="bg-white border-b border-2 text-primary hover:bg-gray-200">
                    <th scope="row" className="py-4 px-6">
                      <Typography
                        variant="caption"
                        fontSize={{ xs: "13px", sm: "16px", md: "20px" }}
                        lineHeight={{ xs: "200%", sm: "180%" }}
                        letterSpacing={"0.02em"}
                        className="text-start"
                      >
                        {author.author}
                      </Typography>
                      {/* </Link> */}
                    </th>
                    <td className="py-4 px-6">
                      <Typography
                        variant="caption"
                        fontSize={{ xs: "12px", sm: "13px", md: "16px" }}
                        lineHeight={{ xs: "200%", sm: "180%" }}
                        letterSpacing={"0.02em"}
                        className="text-start opacity-70"
                      >
                        {author.email}
                      </Typography>
                    </td>
                  </tr>
                </tbody>
              </>
            ))}
        </table>
      </div>
    </React.Fragment>
  );
};

export default AuthorsTable;
