import {
  Alert,
  Box,
  CircularProgress,
  FormGroup,
  Grid,
  TextField,
} from "@mui/material";
import React, { useContext, useState } from "react";

import CRCbutton from "../../../custom/Forms/CRCbutton";
import CRCFileUpload from "../../../custom/Forms/CRCFileUpload";
import CRCSelect from "../../../custom/Forms/CRCSelect";
import CRCTextField from "../../../custom/Forms/CRCTextField";
import { LoaderContext } from "../../../context/loader.context";
import { useMutation } from "@apollo/client";
import { useAuth } from "../../../context/app.context";
import { ADD_POST } from "../../../graphql/other-queries";
import CRCeditor from "../../../custom/Forms/CRCeditor";
import { formatDBdate } from "../../../hook/date-format";

const category = ["biblical", "doctrine"];
const AddPost = () => {
  const { isAuthenticated, adminData, loginUser, logoutUser } = useAuth();
  const loaderContext = useContext(LoaderContext);

  const openLoader = () => {
    loaderContext.setShowState(true);
  };

  const closeLoader = () => {
    loaderContext.setShowState(false);
  };

  const [addPost, { loading, error, data }] = useMutation(ADD_POST);
  const [msg, setMsg] = useState("");
  // const name = userData.firstname + " " + userData.lastname;
  // console.log("Author: ", name);

  const [formData, setFormData] = useState({
    title: "",
    contentpic: "",
    author: adminData.firstname + " " + adminData.lastname,
    content: "",
    category: "",
  });

  const handleCategoryChange = (selectedCategory) => {
    setFormData({ ...formData, category: selectedCategory });
    setMsg("");
  };

  // const handleContent = (content) => {
  //   setFormData({ ...formData, content });
  //   setMsg("");
  // };

  const [contentPic, setContentPic] = useState("");

  const handleContentPicChange = (event) => {
    const selectedFile = event.target.files[0];
    setMsg("");

    if (selectedFile) {
      setFormData({ ...formData, contentpic: selectedFile });
      setContentPic(selectedFile.name);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // openLoader();
    const emptyFields = Object.keys(formData).filter((key) => !formData[key]);

    if (emptyFields.length > 0) {
      // Display error alert for empty fields
      setMsg("Please input all fields");
      return;
    }
    if (formData.imgSrc === "") {
      setMsg("Please Select an Image");
    }

    // console.log("FormData", formData);
    try {
      const { data } = await addPost({
        variables: formData,
      });

      // Handle successful form submission (if needed)
      console.log("Post Created successfully:", data);
      closeLoader();
      setMsg("Post created successfully");
      setTimeout(() => {
        // navigate(`/profile`);
        window.location.reload();
      }, 3000);
    } catch (error) {
      // Display error alert for server error
      console.error("Post Creating error:", error.message);
    }
  };
  return (
    <React.Fragment>
      <div className="overflow-x-hidden relative sm:rounded-lg px-2 md:px-6 lg:px-20 !mx-auto mb-12">
        <form onSubmit={handleSubmit}>
          <FormGroup>
            {loading && <CircularProgress />} {/* Display loading indicator */}
            {msg === "Post created successfully" ? (
              <Alert variant="filled" severity="success">
                {msg}
              </Alert>
            ) : msg === "Please input all fields" ? (
              <Alert variant="filled" severity="error">
                {msg}
              </Alert>
            ) : msg === "Please Select an Image" ? (
              <Alert variant="filled" severity="error">
                {msg}
              </Alert>
            ) : null}
            <br />
            {/* Display error alert */}
            <Grid container spacing={{ xs: 0, md: 2 }}>
              <Grid item xs={12} sm={6} md={6}>
                <CRCTextField
                  label="Title"
                  name="title"
                  type="name"
                  onChange={(e) => {
                    setFormData({ ...formData, title: e.target.value });
                    setMsg("");
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <CRCSelect
                  label="Select Category"
                  values={category} // Assuming country is an array of countries
                  placeholder="Select Category"
                  onChange={handleCategoryChange} // Pass the handler to update the selected country
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={{ xs: 0, sm: 2, md: 2 }}
              className="items-center py-3"
            >
              <Grid item xs={12} md={6} className="">
                <CRCFileUpload
                  label="Select a post image"
                  name="post image"
                  type="file"
                  value={contentPic}
                  onChange={handleContentPicChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  fullWidth
                  variant="standard"
                  label={`Posted By: ${formData.author}`}
                  name="Author"
                  type="name"
                  disabled
                />
              </Grid>
            </Grid>
            <Grid container spacing={{ xs: 0, md: 2 }} className="">
              <Grid item xs={12} md={12}>
                <CRCTextField
                  label="Content"
                  name="content"
                  type="name"
                  multiline
                  onChange={(e) => {
                    setFormData({ ...formData, content: e.target.value });
                    setMsg("");
                  }}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box className="mx-auto mt-8 w-[60%] md:w-[40%]">
                <CRCbutton label={"Add Post"} type={"submit"} />
              </Box>
            </Grid>
          </FormGroup>
        </form>
      </div>
    </React.Fragment>
  );
};

export default AddPost;
