import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import { useContext } from "react";
import { Box, Paper, Typography } from "@mui/material";
import CRCbutton from "../Forms/CRCbutton";
import { SessionModalContext } from "../../context/modal-session-context";
import { useQuery } from "@apollo/client";
import { useAuth } from "../../context/app.context";
import { REFRESH_ADMIN_SESSION } from "../../graphql/admin-queries";
import { useState } from "react";

export default function ActionModal({
  label1,
  label2,
  statement,
  modalstate,
  handleDelete,
  closeModal,
}) {
  const [modal, setmodal] = useState(false);

  // const closeModal = () => {
  //   setmodal(false);
  // };

  return (
    <div>
      {/* <Button  onClick={handleOpen}>Show backdrop</Button> */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={modalstate}
        // onClick={closeModal}
      >
        <Paper elevation={3} className="w-[300px] md:w-[600px] p-4 rounded-md">
          <Box>
            <Typography
              variant="body"
              lineHeight={{ xs: "200%", sm: "180%" }}
              fontSize={{ xs: "20px", sm: "30px" }}
              letterSpacing={"0.02em"}
              className="pl-6"
            >
              {statement}
            </Typography>
          </Box>
          <Box className="mx-auto mt-8 p-4 flex gap-24 place-content-center">
            <form onSubmit={handleDelete}>
              <CRCbutton
                rounded
                label={label1}
                type={"submit"}
                onClick={handleDelete} // Pass the onClick event to the button
              />
            </form>
            <CRCbutton rounded onClick={closeModal} label={label2} />
          </Box>
        </Paper>
      </Backdrop>
    </div>
  );
}
