import React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Box,
  Typography,
  Grid,
  Divider,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import PlainAdminHeader from "./UI/PlainAdminHeader";
import {
  GET_ARTICLES,
  GET_EVENTS,
  GET_PENDING_ARTICLES,
  GET_PERSONNELS,
  GET_POSTS,
  GET_POST_WITH_INTERACTIONS,
  GET_VISITORS,
} from "../../graphql/other-queries";
import { GET_PARTNERS } from "../../graphql/admin-queries";
import { useState } from "react";
import { useEffect } from "react";
import { GET_ADMINS, GET_USERS } from "../../graphql/admin-queries";
import { Link } from "react-router-dom";
// import { card1, card2 } from "./UI/GetStats";

const Dashboard = () => {
  const [ipToday, setIpToday] = useState("");
  const [ipMonthly, setMonthlyIp] = useState("");
  const [admins, setAdmins] = useState("");
  const [users, setUsers] = useState("");
  const [personnels, setPersonnels] = useState("");
  const [partners, setPartners] = useState("");
  const [verifiedArticles, setVerifiedArticles] = useState("");
  const [pendingArticles, setPendingArticles] = useState("");
  const [events, setEvents] = useState("");
  const [upcomingEvents, setUpcomingEvents] = useState("");
  const [posts, setPosts] = useState("");
  const [forumInteractions, setForumInteractions] = useState(0);

  const { data: userdata } = useQuery(GET_USERS, {
    pollInterval: 500,
  });
  const { data: admindata } = useQuery(GET_ADMINS, {
    pollInterval: 500,
  });
  const { data: personneldata } = useQuery(GET_PERSONNELS, {
    pollInterval: 500,
  });
  const { data: ipdata } = useQuery(GET_VISITORS, {
    pollInterval: 500,
  });
  const { data: articledata } = useQuery(GET_ARTICLES, {
    pollInterval: 500,
  });
  const { data: partnerdata } = useQuery(GET_PARTNERS, {
    pollInterval: 500,
  });
  const { data: pendingarticles } = useQuery(GET_PENDING_ARTICLES, {
    pollInterval: 500,
  });
  const { data: eventdata } = useQuery(GET_EVENTS, {
    pollInterval: 500,
  });
  const { data: postdata } = useQuery(GET_POSTS, {
    pollInterval: 500,
  });
  const { data: interactiondata } = useQuery(GET_POST_WITH_INTERACTIONS, {
    pollInterval: 500,
  });

  // Get and Set Daily and Monthly Page Visitors
  useEffect(() => {
    if (ipdata) {
      // Get the current month (0-indexed, so January is 0)
      const currentMonth = new Date().getMonth();

      // Filter the visitors by checking if their timestamps match the current month
      const visitorsThisMonth =
        ipdata &&
        ipdata.getVisitors.filter((visitor) => {
          const visitorDate = new Date(visitor.createdAt);
          return visitorDate.getMonth() === currentMonth;
        });

      // Sort the filtered array if needed (replace 'timestamp' with the actual date property)
      // visitorsThisMonth.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

      // console.log(visitorsThisMonth, "Visitors this month");
      setMonthlyIp(visitorsThisMonth);
    }

    if (ipdata) {
      console.log(ipdata, "IPPP");
      // Get the current date and format it to exclude the time portion
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      // Filter the visitors by checking if their createdAt matches today's date
      const visitorsToday = ipdata.getVisitors.filter((visitor) => {
        const visitorDate = new Date(visitor.createdAt);
        visitorDate.setHours(0, 0, 0, 0);
        return visitorDate.getTime() === currentDate.getTime();
      });

      // console.log(visitorsToday.length, "Visitors today");
      setIpToday(visitorsToday);
    }
  }, [ipdata]);

  // Get All Users
  useEffect(() => {
    if (userdata) {
      // console.log(userdata.getUsers);
      setUsers(userdata.getUsers);
    }
  }, [userdata]);

  // Get All Admins
  useEffect(() => {
    if (admindata) {
      // console.log(admindata.getAdmins);
      setAdmins(admindata.getAdmins);
    }
  }, [admindata]);

  // Get All Partners
  useEffect(() => {
    if (partnerdata) {
      // console.log(partnerdata.getPartners);
      setPartners(partnerdata.getPartners);
    }
  }, [partnerdata]);

  // Get All Personnels
  useEffect(() => {
    if (personneldata) {
      // console.log(personneldata.getPersonnels);
      setPersonnels(personneldata.getPersonnels);
    }
  }, [personneldata]);

  // // Get all authors emails
  // useEffect(() => {
  //   if (articledata) {
  //     // Extract all the email addresses into a new array
  //     const emailArray = articledata.getArticles.map((author) => author.email);

  //     // Use a Set to remove duplicates
  //     const uniqueEmails = [...new Set(emailArray)];

  //     // Now, uniqueEmails contains all the unique email addresses
  //     // console.log(uniqueEmails);
  //     setAuthors(uniqueEmails);
  //   }
  // }, [articledata]);

  // Get All Verified Articles
  useEffect(() => {
    if (articledata) {
      // console.log(articledata.getArticles);
      setVerifiedArticles(articledata.getArticles);
    }
  }, [articledata]);

  // Get All Pending Articles
  useEffect(() => {
    if (pendingarticles) {
      // console.log(pendingarticles.getPendingArticles);
      setPendingArticles(pendingarticles.getPendingArticles);
    }
  }, [pendingarticles]);

  // Get All Events
  useEffect(() => {
    if (eventdata) {
      // console.log(eventdata.getEvents);
      setEvents(eventdata.getEvents);
    }
  }, [eventdata]);

  // Get Upcoming Events
  useEffect(() => {
    if (eventdata) {
      // console.log(eventdata.getEvents);
      const today = new Date(); // Get today's date
      const filteredEvents = eventdata.getEvents.filter(
        (event) => new Date(event.date) > today
      );
      // console.log("Upcoming", filteredEvents);
      setUpcomingEvents(filteredEvents);
    }
  }, [eventdata]);

  // Get All Posts
  useEffect(() => {
    if (postdata) {
      // console.log(postdata.getPosts);
      setPosts(postdata.getPosts);
    }
  }, [postdata]);

  // Get All Forum
  useEffect(() => {
    if (interactiondata) {
      // console.log("Forum", interactiondata.getPostWithInteractions);
      setForumInteractions(interactiondata.getPostWithInteractions);
    }
  }, [interactiondata]);

  const card1 = [
    {
      imgSrc: `${process.env.REACT_APP_S3_BUCKET_URL}/designs/color4_tjygjz.jpg`,
      title: "Users",
      stats: users.length,
      url: "/users",
    },
    {
      imgSrc: `${process.env.REACT_APP_S3_BUCKET_URL}/designs/black_ki8sxg.jpg`,
      title: "CRC Personnels",
      stats: personnels.length,
      url: "/personnels",
    },
    {
      imgSrc: `${process.env.REACT_APP_S3_BUCKET_URL}/designs/red_mipl8n.jpg`,
      title: "Partners",
      stats: partners.length,
      url: "/crc-partners",
    },
    {
      imgSrc: `${process.env.REACT_APP_S3_BUCKET_URL}/designs/deepblue_bqzgua.jpg`,
      title: "Admins",
      stats: admins.length,
      url: "/admins",
    },
  ];
  const card2 = [
    {
      imgSrc: `${process.env.REACT_APP_S3_BUCKET_URL}/designs/black_gtdzh4.jpg`,
      title: "Page Visits",
      text1: "Daily",
      stats1: ipToday.length || 0,
      text2: "Monthly",
      stats2: ipMonthly.length || 0,
      url: "/",
    },
    {
      imgSrc: `${process.env.REACT_APP_S3_BUCKET_URL}/designs/carpetred_oiwbnl.jpg`,
      title: "Articles",
      text1: "Verified",
      stats1: verifiedArticles.length,
      text2: "Pending",
      stats2: pendingArticles.length,
      url: "/articles",
    },
    {
      imgSrc: `${process.env.REACT_APP_S3_BUCKET_URL}/designs/yellow_w6azgj.jpg`,
      title: "Events",
      text1: "Total",
      stats1: events.length,
      text2: "Upcoming",
      stats2: upcomingEvents.length,
      url: "/events",
    },
    {
      imgSrc: `${process.env.REACT_APP_S3_BUCKET_URL}/designs/night_hhw0xg.jpg`,
      title: "Community",
      text1: "Total Post",
      stats1: posts.length,
      text2: "Interactions",
      stats2: forumInteractions && forumInteractions.totalInteractions,
      url: "/community",
    },
  ];

  return (
    <PlainAdminHeader>
      <Box className="mt-16">
        <Grid
          container
          spacing={{ xs: 0, sm: 2, md: 3 }}
          className="md:flex items-center"
        >
          {card1.map((item) => (
            <Grid item xs={12} sm={6} md={3} className="py-2 relative">
              <Link to={item.url} key={item.title}>
                {/* <Box>
                <Box className="absolute  w-full  h-[260px] bg-white opacity-[0.15] bg-cover bg-center" />
                <img
                  src={item.imgSrc}
                  alt={item.title}
                  className=" w-80 h-64 rounded-t-2xl object-cover"
                />
              </Box>
              <Box className="absolute z-40 top-[20%] md:top-[20%] left-10 ">
                <Typography
                  variant="header"
                  color="white"
                  fontSize={{ xs: "50px", sm: "50px", md: "50px" }}
                  lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className="text-start drop-shadow-2xl"
                >
                  {item.stats}
                </Typography>
                <br />
                <Typography
                  variant="body"
                  color="white"
                  fontSize={{ xs: "35px", sm: "35px", md: "35px" }}
                  lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className="text-end drop-shadow-2xl"
                >
                  {item.title}
                </Typography>
              </Box> */}
                <Card
                  sx={{ maxWidth: 345 }}
                  className="drop-shadow-2xl shadow-2xl rounded-t-2xl"
                >
                  <CardActionArea>
                    <Box className="absolute top-0 h-64 w-full bg-white opacity-[0.15] bg-cover bg-center" />

                    <CardMedia
                      component="img"
                      // height="140"
                      image={item.imgSrc}
                      alt={item.title}
                      className="object-cover h-64 w-80"
                    />
                    <Box className="absolute z-40 top-[20%] md:top-[20%] left-10 ">
                      <Typography
                        variant="header"
                        component={"div"}
                        color="white"
                        fontSize={{ xs: "50px", sm: "50px", md: "50px" }}
                        lineHeight={{ xs: "200%", sm: "180%" }}
                        letterSpacing={"0.02em"}
                      >
                        {item.stats}
                      </Typography>
                      <Typography
                        variant="body"
                        color="white"
                        fontSize={{ xs: "30px", sm: "30px", md: "30px" }}
                        lineHeight={{ xs: "200%", sm: "180%" }}
                        letterSpacing={"0.02em"}
                      >
                        {item.title}
                      </Typography>
                    </Box>
                  </CardActionArea>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>

        {/* Second Card */}
        <Grid
          container
          spacing={{ xs: 0, sm: 2, md: 3 }}
          className="md:flex items-center"
        >
          {card2.map((item) => (
            <Grid item xs={12} sm={6} md={3} className="py-2 relative">
              <Link to={item.url} key={item.title}>
                <Card
                  sx={{ maxWidth: 345 }}
                  className="drop-shadow-2xl shadow-2xl rounded-b-2xl"
                >
                  <CardActionArea>
                    <Box className="absolute top-0 h-52 w-full bg-white opacity-[0.15] bg-cover bg-center" />

                    <CardMedia
                      component="img"
                      // height="140"
                      image={item.imgSrc}
                      alt={item.title}
                      className="object-cover h-52 w-80"
                    />
                    <Typography
                      variant="body"
                      color="white"
                      fontSize={{ xs: "30px", sm: "30px", md: "30px" }}
                      lineHeight={{ xs: "200%", sm: "180%" }}
                      letterSpacing={"0.02em"}
                      className="absolute z-40 top-[20%] md:top-[20%] left-[68px]"
                    >
                      {item.title}
                    </Typography>

                    <CardContent className="flex place-content-evenly">
                      <Box className="text-center">
                        <Typography
                          gutterBottom
                          component={"div"}
                          variant="subheader"
                          color="primary"
                          fontSize={{ xs: "20px", sm: "20px", md: "20px" }}
                          lineHeight={{ xs: "200%", sm: "180%" }}
                        >
                          {item.text1}
                        </Typography>
                        <Typography
                          variant="body"
                          color="primary"
                          fontSize={{ xs: "30px", sm: "30px", md: "30px" }}
                          lineHeight={{ xs: "200%", sm: "180%" }}
                          letterSpacing={"0.02em"}
                          className="bg-gray-200 !rounded-full py-2 px-4"
                        >
                          {item.stats1}
                        </Typography>
                      </Box>

                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                      />

                      <Box className="text-center">
                        <Typography
                          gutterBottom
                          component={"div"}
                          variant="subheader"
                          color="primary"
                          fontSize={{ xs: "20px", sm: "20px", md: "20px" }}
                          lineHeight={{ xs: "200%", sm: "180%" }}
                        >
                          {item.text2}
                        </Typography>
                        <Typography
                          variant="body"
                          color="primary"
                          fontSize={{ xs: "30px", sm: "30px", md: "30px" }}
                          lineHeight={{ xs: "200%", sm: "180%" }}
                          letterSpacing={"0.02em"}
                          className="bg-gray-200 !rounded-full py-2 px-4"
                        >
                          {item.stats2}
                        </Typography>
                      </Box>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Box>
    </PlainAdminHeader>
  );
};

export default Dashboard;
