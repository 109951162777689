import { Box, IconButton, Typography, Alert } from "@mui/material";
import React from "react";
import { formatDay } from "../../../hook/date-format";
import { Link, useNavigate } from "react-router-dom";

import { Edit, Delete, Visibility } from "@mui/icons-material";
import { DELETE_PERSONNEL } from "../../../graphql/other-queries";
import { useMutation } from "@apollo/client";
import ActionModal from "../../../custom/Layout/ActionModal";
import { useState } from "react";
import Pagination from "../../../custom/Pagination/Pagination";

const PersonnelTable = ({ data }) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage, setPostsPerPage] = React.useState(10);

  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentData = data.slice(firstPostIndex, lastPostIndex);

  let navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [msg, setmsg] = useState("");
  const [ID, setID] = useState(0);

  const [deletePersonnel, { loading, error, data: personnelData }] =
    useMutation(DELETE_PERSONNEL);

  const handleView = (memberID) => {
    // memberID.preventDefault();

    // console.log("Edit ID:", memberID);
    navigate(`/personnels/${memberID}`);
  };

  const handleEdit = (memberID) => {
    // memberID.preventDefault();

    // console.log("Edit ID:", memberID);
    navigate(`/personnels/edit/${memberID}`);
  };

  const handleDel = async (e, memberID) => {
    e.preventDefault();
    console.log("Delete ID:", memberID);

    try {
      const result = await deletePersonnel({
        variables: { id: memberID },
      });

      // Handle the result as needed, e.g., show a success message
      console.log("Member deleted successfully", result);
      setmsg("Member deleted successfully");
      setShow(false);
    } catch (error) {
      // Handle any errors
      console.error("Error deleting Member", error);
      setmsg("Error deleting Member");
    }
  };
  return (
    <React.Fragment>
      <div className="overflow-x-auto relative sm:rounded-lg px-2 md:px-6 lg:px-20 mx-auto mb-12">
        {msg === "Member deleted successfully" ? (
          <Alert variant="filled" severity="success">
            {msg}
          </Alert>
        ) : msg === "Error deleting Member" ? (
          <Alert variant="filled" severity="error">
            {msg}
          </Alert>
        ) : null}
        <table className="w-full text-sm text-left px-12 mt-3">
          <thead className=" bg-[#263238]">
            {/* bg-[#ab47bc] */}
            <tr>
              <th scope="col" className="py-3 px-6">
                <Typography
                  variant="subheader"
                  fontSize={{ xs: "15px", sm: "20px" }}
                  lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className=" uppercase"
                  color={"white"}
                >
                  Personnel
                </Typography>
              </th>
              <th scope="col" className="py-3 px-6">
                <Typography
                  variant="subheader"
                  fontSize={{ xs: "15px", sm: "20px" }}
                  lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className=" uppercase"
                  color={"white"}
                >
                  Category
                </Typography>
              </th>
              <th scope="col" className="py-3 px-6 text-center">
                <Typography
                  variant="subheader"
                  fontSize={{ xs: "15px", sm: "20px" }}
                  lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className=" uppercase"
                  color={"white"}
                >
                  Action
                </Typography>
              </th>
            </tr>
          </thead>
          {currentData &&
            currentData.map((member, index) => (
              <tbody key={index}>
                <tr className="bg-white border-b border-2 text-primary hover:bg-gray-200">
                  <th scope="row" className="py-4 px-6">
                    <Typography
                      variant="caption"
                      fontSize={{ xs: "13px", sm: "16px", md: "20px" }}
                      lineHeight={{ xs: "200%", sm: "180%" }}
                      letterSpacing={"0.02em"}
                      className="text-start"
                    >
                      {member.firstname + " " + member.lastname}
                    </Typography>
                    {/* </Link> */}
                  </th>
                  <td className="py-4 px-6">
                    <Typography
                      variant="caption"
                      fontSize={{ xs: "12px", sm: "13px", md: "16px" }}
                      lineHeight={{ xs: "200%", sm: "180%" }}
                      letterSpacing={"0.02em"}
                      className="text-start opacity-70"
                    >
                      {member.category}
                    </Typography>
                  </td>
                  <td className="py-4 px-6">
                    <Box className="flex gap-2 items-center place-content-center !text-center">
                      <IconButton
                        aria-label="edit"
                        onClick={() => {
                          handleView(member.id);
                        }}
                        color="primary"
                      >
                        <Visibility />
                      </IconButton>
                      <IconButton
                        aria-label="edit"
                        onClick={() => {
                          handleEdit(member.id);
                        }}
                        color="primary"
                      >
                        <Edit />
                      </IconButton>

                      <IconButton
                        aria-label="delete"
                        onClick={() => {
                          // handleDelete(member.id);
                          setID(member.id);
                          setShow(true);
                        }}
                        color="primary"
                      >
                        <Delete />
                      </IconButton>
                    </Box>
                  </td>
                </tr>
              </tbody>
            ))}
        </table>

        <div className=" mx-auto">
          <Pagination
            totalPosts={data.length}
            postsPerPage={postsPerPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </div>

        {show && (
          <ActionModal
            statement={"Do you wish to delete CRC Member?"}
            label1={"Delete"}
            label2={"Cancel"}
            modalstate={show}
            closeModal={() => setShow(false)}
            handleDelete={(e) => handleDel(e, ID)}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default PersonnelTable;
