import {
  Alert,
  Box,
  CircularProgress,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";

import { useQuery } from "@apollo/client";
import { GET_PERSONNEL } from "../../../graphql/other-queries";
import PlainAdminHeader from "../UI/PlainAdminHeader";

const ViewPersonnel = () => {
  const [member, setMember] = useState("");
  let navigate = useNavigate();
  const params = useParams();

  const { data: personnelData } = useQuery(GET_PERSONNEL, {
    variables: { id: params.id },
    pollInterval: 500,
  });

  React.useEffect(() => {
    if (personnelData) {
      // console.log("Members", personnelData.getPersonnelById);
      const member = personnelData.getPersonnelById;
      setMember(member);
    }
  }, [personnelData]);
  return (
    <React.Fragment>
      <PlainAdminHeader>
        <Box className="mt-16">
          <div className="overflow-x-hidden relative sm:rounded-lg px-2 md:px-6 !mx-auto mb-12">
            {/* Display loading indicator */}

            {/* Display error alert */}
            <Grid container spacing={{ xs: 0, md: 2 }}>
              <Grid item xs={12} sm={6} md={6}></Grid>
              <Box className="bg-white lg:px-10 md:px-16 px-6 py-12 mx-auto">
                <Box className=" mx-auto place-content-center items-center w-full h-[400px] md:h-[500px] lg:h-[700px] py-4">
                  <img
                    className="object-contain rounded-md bg-contain place-content-center mx-auto h-full md:w-full"
                    src={member.avatar}
                    alt={member.title}
                    title="Click to view more"
                  />
                </Box>
                <Box className="text-center">
                  <Typography
                    variant="header"
                    fontSize={{ xs: "20px", sm: "30px" }}
                    lineHeight={{ xs: "200%", sm: "180%" }}
                    letterSpacing={"0.02em"}
                    className=" uppercase"
                    color="primary"
                  >
                    {member.firstname + " " + member.lastname}
                  </Typography>{" "}
                  <br />
                  <Typography
                    variant="subheader"
                    fontSize={{ xs: "20px", sm: "20px" }}
                    lineHeight={{ xs: "200%", sm: "180%" }}
                    letterSpacing={"0.02em"}
                    className=" capitalize py-2"
                    color="primary"
                  >
                    {member.category}
                  </Typography>
                </Box>

                {/* Member Editor Content */}

                <Box className="overflow-hidden text-center py-2">
                  <div
                    style={{ wordWrap: "break-word", whiteSpace: "normal" }}
                    dangerouslySetInnerHTML={{ __html: member.bio }}
                  />
                </Box>
              </Box>
            </Grid>
          </div>
        </Box>
      </PlainAdminHeader>
    </React.Fragment>
  );
};

export default ViewPersonnel;
