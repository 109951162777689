import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Editor = ReactQuill.Quill; // Import Quill from ReactQuill

// Define the modules and formats outside of the CRCeditor component
Editor.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }],
    [{ size: ["Normal"] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ color: [] }, { background: [] }],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: true,
  },
};

Editor.formats = [
  "header",
  "bold",
  "italic",
  "color",
  "blockquote",
  "background",
  "underline",
  "strike",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

const CRCeditor = ({ value, onChange }) => {
  return (
    <React.Fragment>
      <ReactQuill
        theme="snow"
        value={value}
        onChange={onChange}
        modules={Editor.modules} // Use the defined modules
        formats={Editor.formats} // Use the defined formats
        className="h-64 pb-10 md:pb-3"
      />
    </React.Fragment>
  );
};

export default CRCeditor;
