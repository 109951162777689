// AuthContext.js
import React, { createContext, useContext, useState } from "react";
import Cookies from "universal-cookie";
import jwtDecode from "jwt-decode";
import { useQuery } from "@apollo/client";
import { GET_ADMIN } from "../graphql/admin-queries";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const cookies = new Cookies();
  const initialToken = cookies.get("crc-admin-token") || null;

  const [token, setToken] = useState(initialToken);
  const [shouldReload, setShouldReload] = useState(false);

  React.useEffect(() => {
    if (shouldReload) {
      setShouldReload(false); // Reset shouldReload once the page reloads
      window.location.reload();
    }
  }, [shouldReload]);

  const loginAdmin = (newToken) => {
    setToken(newToken);
    cookies.set("crc-admin-token", newToken, { path: "/" });
  };

  const logoutAdmin = () => {
    setToken(null);
    cookies.remove("crc-admin-token");
    setShouldReload(true);
  };

  const isAuthenticated = !!token;

  React.useEffect(() => {
    // Function to check token expiration
    const checkTokenExpiration = () => {
      const jwtToken = cookies.get("crc-admin-token");
      if (jwtToken) {
        // Parse the JWT token to get its payload
        const tokenParts = jwtToken.split(".");
        if (tokenParts.length === 3) {
          const payload = JSON.parse(atob(tokenParts[1]));

          // Get the expiration timestamp from the payload
          const expirationTime = payload.exp * 1000; // Convert to milliseconds

          // Compare with current time
          if (expirationTime <= Date.now()) {
            // Token has expired, log the admin out
            logoutAdmin();
          }
        }
      }
    };

    // Check token expiration every 10sec
    const interval = setInterval(checkTokenExpiration, 100);

    // Clean up interval on unmount
    return () => clearInterval(interval);
  }, []);

  let secretKey = process.env.REACT_APP_SECRET_KEY;

  const getAdminIdFromToken = (token) => {
    try {
      const decodedToken = jwtDecode(token, secretKey);
      const adminId = decodedToken.adminId;
      return adminId;
    } catch (error) {
      // console.error("Error decoding token:", error);
      return null;
    }
  };

  const adminId = getAdminIdFromToken(token);

  // Using the GET_ADMIN query
  const { loading, error, data } = useQuery(GET_ADMIN, {
    variables: { id: adminId },
  });

  const [adminData, setAdminData] = useState({
    email: "",
    firstname: "",
    lastname: "",
    id: "",
    avatar: "",
    role: "",
    createdAt: "",
  });

  React.useEffect(() => {
    if (data && data.getAdminById) {
      const admin = data.getAdminById; // Assuming getAdminById is the correct field name
      setAdminData((prevAdminData) => ({
        ...prevAdminData,
        id: admin.id,
        email: admin.email,
        firstname: admin.firstname,
        lastname: admin.lastname,
        avatar: admin.avatar,
        role: admin.role,
        createdAt: admin.createdAt,
      }));
    }
  }, [data]);

  React.useEffect(() => {
    if (data && data.getAdminById === null) {
      // console.log("Admin Not Found");
      setToken(null);
      cookies.remove("crc-admin-token");
    }
  }, [data]);

  return (
    <AuthContext.Provider
      value={{ token, isAuthenticated, adminData, loginAdmin, logoutAdmin }}
    >
      {children}
    </AuthContext.Provider>
  );
};
