import {
  Alert,
  Box,
  CircularProgress,
  FormGroup,
  Grid,
  TextField,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";

import CRCbutton from "../../../custom/Forms/CRCbutton";
import CRCFileUpload from "../../../custom/Forms/CRCFileUpload";
import { LoaderContext } from "../../../context/loader.context";
import { useMutation, useQuery } from "@apollo/client";
import { GET_EVENT, UPDATE_EVENT } from "../../../graphql/other-queries";
import CRCeditor from "../../../custom/Forms/CRCeditor";
import { formatDBdate, formatDay } from "../../../hook/date-format";
import PlainAdminHeader from "../UI/PlainAdminHeader";

const UpdateEvent = () => {
  const loaderContext = useContext(LoaderContext);
  const [event, setEvent] = useState("");
  let navigate = useNavigate();
  const params = useParams();

  const openLoader = () => {
    loaderContext.setShowState(true);
  };

  const closeLoader = () => {
    loaderContext.setShowState(false);
  };

  const { data: eventData } = useQuery(GET_EVENT, {
    variables: { id: params.id },
    pollInterval: 500,
  });

  React.useEffect(() => {
    if (eventData) {
      // console.log("EVENTS", eventData.getEventById);
      //   Filter Articles by like
      const event = eventData.getEventById;
      setEvent(event);
    }
  }, [eventData]);
  const [updateEvent, { loading, error, data: updateEventData }] =
    useMutation(UPDATE_EVENT);
  const [msg, setMsg] = useState("");

  const [title, settitle] = useState("");
  const [imgSrc, setimgSrc] = useState("");
  const [venue, setvenue] = useState("");
  const [content, setcontent] = useState("");
  const [date, setdate] = useState("");

  const [formData, setFormData] = useState({
    id: 0,
    title: "",
    imgSrc: "",
    venue: "",
    content: "",
    date: "",
  });

  React.useEffect(() => {
    setFormData({
      ...formData,
      id: event.id,
      title: title || event.title,
      imgSrc: imgSrc || event.imgSrc,
      venue: venue || event.venue,
      content: content || event.content,
      date: date || event.date,
    });

    // console.log("formData: ", formData);
  }, [
    title,
    imgSrc,
    venue,
    content,
    formData.content,
    date,
    eventData,
    params.id,
  ]);

  const handleContent = (content) => {
    setFormData({ ...formData, content });
    setcontent(content);
    setMsg("");
  };

  const handleContentPicChange = (event) => {
    const selectedFile = event.target.files[0];
    setMsg("");

    if (selectedFile) {
      // setFormData({ ...formData, imgSrc: selectedFile.name });
      setimgSrc(selectedFile.name);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // openLoader();
    const emptyFields = Object.keys(formData).filter((key) => !formData[key]);

    if (emptyFields.length > 0) {
      // Display error alert for empty fields
      return;
    }

    // console.log("FormData", formData);
    try {
      const { data } = await updateEvent({
        variables: formData,
      });

      // Handle successful form submission (if needed)
      console.log("Event Update successfully:", data);
      closeLoader();
      setMsg("Event Update successfully");
      setTimeout(() => {
        navigate(`/events`);
      }, 3000);
    } catch (error) {
      // Display error alert for server error
      console.error("Event Update error:", error.message);
    }
  };
  return (
    <React.Fragment>
      <PlainAdminHeader>
        <Box className="mt-16">
          <div className="overflow-x-hidden relative sm:rounded-lg px-2 md:px-6 lg:px-20 !mx-auto mb-12">
            <form onSubmit={handleSubmit}>
              <FormGroup>
                {loading && <CircularProgress />}{" "}
                {/* Display loading indicator */}
                {msg === "Event Update successfully" ? (
                  <Alert variant="filled" severity="success">
                    {msg}
                  </Alert>
                ) : msg === "Please Select an Image" ? (
                  <Alert variant="filled" severity="error">
                    {msg}
                  </Alert>
                ) : null}
                <br />
                {/* Display error alert */}
                <Grid container spacing={{ xs: 0, sm: 2, md: 2 }}>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      fullWidth
                      variant="standard"
                      label=""
                      name="title"
                      type="name"
                      placeholder={event.title}
                      value={title || formData.title}
                      onChange={(e) => {
                        // setFormData({ ...formData, title: e.target.value });
                        settitle(e.target.value);
                        setMsg("");
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      fullWidth
                      variant="standard"
                      label=""
                      name="Venue"
                      type="name"
                      placeholder={event.venue}
                      value={venue || formData.venue}
                      onChange={(e) => {
                        // setFormData({ ...formData, venue: e.target.value });
                        setvenue(e.target.value);
                        setMsg("");
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={{ xs: 0, sm: 2, md: 2 }}
                  className="items-center py-3"
                >
                  <Grid item xs={12} sm={6} className="!mt-4">
                    <TextField
                      fullWidth
                      variant="standard"
                      value={formData.date}
                      placeholder={event.date}
                      label={formData.date === "" ? "" : formData.date}
                      name="date"
                      type="date"
                      onChange={(e) => {
                        let date = formatDBdate(e.target.value);
                        // setFormData({ ...formData, date: date });
                        setdate(date);
                        console.log("DOB", date);
                        setMsg("");
                      }}
                    />
                    {formatDay(formData.date || event.date)}
                  </Grid>

                  <Grid item xs={12} sm={6} className="">
                    <CRCFileUpload
                      label={formData.imgSrc || event.imgSrc}
                      name="event image"
                      type="file"
                      value={imgSrc}
                      onChange={handleContentPicChange}
                      disabled
                    />
                    <Link to={formData.imgSrc || event.imgSrc} target="_blank">
                      <p className="text-xs">
                        {formData.imgSrc || event.imgSrc}
                      </p>
                    </Link>
                  </Grid>
                </Grid>
                <Grid container spacing={{ xs: 0, md: 2 }} className="">
                  <Grid item xs={12} md={12}>
                    {/* <CRCeditor
                      value={formData.content}
                      onChange={handleContent}
                    /> */}
                    <TextField
                      fullWidth
                      variant="standard"
                      label=""
                      name="Venue"
                      type="name"
                      placeholder={event.content}
                      value={content || formData.content}
                      onChange={(e) => {
                        // setFormData({ ...formData, venue: e.target.value });
                        setcontent(e.target.value);
                        setMsg("");
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box className="mx-auto mt-8 w-[60%] md:w-[40%]">
                    <CRCbutton label={"Update Event"} type={"submit"} />
                  </Box>
                </Grid>
              </FormGroup>
            </form>
          </div>
        </Box>
      </PlainAdminHeader>
    </React.Fragment>
  );
};

export default UpdateEvent;
