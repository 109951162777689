import {
  Alert,
  Box,
  CircularProgress,
  FormGroup,
  Grid,
  TextField,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";

import CRCbutton from "../../../custom/Forms/CRCbutton";
import CRCFileUpload from "../../../custom/Forms/CRCFileUpload";
import { LoaderContext } from "../../../context/loader.context";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_PERSONNEL,
  UPDATE_PERSONNEL,
} from "../../../graphql/other-queries";
import CRCeditor from "../../../custom/Forms/CRCeditor";
import { formatDBdate, formatDay } from "../../../hook/date-format";
import PlainAdminHeader from "../UI/PlainAdminHeader";

const UpdatePersonnel = () => {
  const loaderContext = useContext(LoaderContext);
  const [member, setMember] = useState("");
  let navigate = useNavigate();
  const params = useParams();

  const openLoader = () => {
    loaderContext.setShowState(true);
  };

  const closeLoader = () => {
    loaderContext.setShowState(false);
  };

  const { data: personnelData } = useQuery(GET_PERSONNEL, {
    variables: { id: params.id },
    pollInterval: 500,
  });

  React.useEffect(() => {
    if (personnelData) {
      // console.log("Member", personnelData.getPersonnelById);
      //   Filter Articles by like
      const member = personnelData.getPersonnelById;
      setMember(member);
    }
    console.log(personnelData);
  }, [personnelData]);
  const [updatePersonnel, { loading, error, data: updateMemberData }] =
    useMutation(UPDATE_PERSONNEL);
  const [msg, setMsg] = useState("");

  const [firstname, setfirstname] = useState("");
  const [avatar, setavatar] = useState("");
  const [lastname, setlastname] = useState("");
  const [social, setsocial] = useState("");
  const [dob, setdob] = useState("");
  const [bio, setbio] = useState("");

  const [formData, setFormData] = useState({
    id: 0,
    firstname: "",
    lastname: "",
    avatar: "",
    dob: "",
    bio: "",
    social: "",
  });

  React.useEffect(() => {
    setFormData({
      ...formData,
      id: member.id,
      firstname: firstname || member.firstname,
      lastname: lastname || member.lastname,
      bio: bio || member.bio,
      social: social || member.social,
      dob: dob || member.dob,
      avatar: avatar || member.avatar,
    });

    // console.log("formData: ", formData);
  }, [firstname, lastname, dob, bio, social, avatar, personnelData, params.id]);

  const handleContentPicChange = (event) => {
    const selectedFile = event.target.files[0];
    setMsg("");

    if (selectedFile) {
      // setFormData({ ...formData, imgSrc: selectedFile.name });
      setavatar(selectedFile.name);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // openLoader();
    const emptyFields = Object.keys(formData).filter((key) => !formData[key]);

    if (emptyFields.length > 0) {
      // Display error alert for empty fields
      return;
    }

    console.log("FormData", formData);
    try {
      const { data } = await updatePersonnel({
        variables: formData,
      });

      // Handle successful form submission (if needed)
      console.log("Member Updated successfully:", data);
      closeLoader();
      setMsg("Member Updated successfully");
      setTimeout(() => {
        navigate(`/personnels`);
      }, 3000);
    } catch (error) {
      // Display error alert for server error
      console.error("Member Updated error:", error.message);
    }
  };
  return (
    <React.Fragment>
      <PlainAdminHeader>
        <Box className="mt-16">
          <div className="overflow-x-hidden relative sm:rounded-lg px-2 md:px-6 lg:px-20 !mx-auto mb-12">
            <form onSubmit={handleSubmit}>
              <FormGroup>
                {loading && <CircularProgress />}{" "}
                {/* Display loading indicator */}
                {msg === "Member Updated successfully" ? (
                  <Alert variant="filled" severity="success">
                    {msg}
                  </Alert>
                ) : msg === "Please Select an Image" ? (
                  <Alert variant="filled" severity="error">
                    {msg}
                  </Alert>
                ) : null}
                <br />
                {/* Display error alert */}
                <Grid container spacing={{ xs: 0, sm: 2, md: 2 }}>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      fullWidth
                      variant="standard"
                      label=""
                      name="firstname"
                      type="name"
                      placeholder={member.firstname}
                      value={firstname || formData.firstname}
                      onChange={(e) => {
                        // setFormData({ ...formData, firstname: e.target.value });
                        setfirstname(e.target.value);
                        setMsg("");
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      fullWidth
                      variant="standard"
                      label=""
                      name="lastname"
                      type="name"
                      placeholder={member.lastname}
                      value={lastname || formData.lastname}
                      onChange={(e) => {
                        // setFormData({ ...formData, lastname: e.target.value });
                        setlastname(e.target.value);
                        setMsg("");
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={{ xs: 0, sm: 2, md: 2 }}
                  className="items-center py-3"
                >
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      fullWidth
                      variant="standard"
                      label=""
                      name="social"
                      type="name"
                      placeholder={member.social}
                      value={social || formData.social}
                      onChange={(e) => {
                        // setFormData({ ...formData, social: e.target.value });
                        setsocial(e.target.value);
                        setMsg("");
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} className="!mt-4">
                    <TextField
                      fullWidth
                      variant="standard"
                      value={formData.dob}
                      placeholder={member.dob}
                      label={formData.dob === "" ? "" : formData.dob}
                      name="dob"
                      type="date"
                      onChange={(e) => {
                        let date = formatDBdate(e.target.value);
                        // setFormData({ ...formData, date: date });
                        setdob(date);
                        console.log("DOB", date);
                        setMsg("");
                      }}
                    />
                    {formatDay(formData.dob || member.dob)}
                  </Grid>

                  {/* <Grid item xs={12} md={6} className="">
                    <CRCFileUpload
                      label="Select an member image"
                      name="member image"
                      type="file"
                      value={avatar}
                      onChange={handleContentPicChange}
                    />
                    <Link to={formData.avatar || member.avatar} target="_blank">
                      <p className="text-xs">
                        {formData.avatar || member.avatar}
                      </p>
                    </Link>
                  </Grid> */}
                </Grid>
                <Grid container spacing={{ xs: 0, md: 2 }} className="">
                  <Grid item xs={12} sm={12}>
                    {/* <CRCeditor
                      value={formData.content}
                      onChange={handleContent}
                    /> */}
                    <TextField
                      fullWidth
                      variant="standard"
                      label=""
                      multiline
                      rows={2}
                      name="bio"
                      type="name"
                      placeholder={member.bio}
                      value={bio || formData.bio}
                      onChange={(e) => {
                        // setFormData({ ...formData, bio: e.target.value });
                        setbio(e.target.value);
                        setMsg("");
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box className="mx-auto mt-8 w-[60%] md:w-[40%]">
                    <CRCbutton label={"Update Member"} type={"submit"} />
                  </Box>
                </Grid>
              </FormGroup>
            </form>
          </div>
        </Box>
      </PlainAdminHeader>
    </React.Fragment>
  );
};

export default UpdatePersonnel;
