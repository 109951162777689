import { Box, IconButton, Typography, Alert } from "@mui/material";
import React from "react";
import { formatDay } from "../../../hook/date-format";
import { useNavigate } from "react-router-dom";

import { Delete } from "@mui/icons-material";
import { useMutation } from "@apollo/client";
import ActionModal from "../../../custom/Layout/ActionModal";
import { useState } from "react";
import { DELETE_ADMIN } from "../../../graphql/admin-queries";
import { useAuth } from "../../../context/app.context";
import Pagination from "../../../custom/Pagination/Pagination";

const AdminTable = ({ data }) => {
  const { adminData } = useAuth();

  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage, setPostsPerPage] = React.useState(10);

  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentData = data.slice(firstPostIndex, lastPostIndex);

  const [show, setShow] = useState(false);
  const [msg, setmsg] = useState("");
  const [ID, setID] = useState(0);

  const [deleteAdmin, { loading, error, data: userData }] =
    useMutation(DELETE_ADMIN);

  const handleDel = async (e, adminID) => {
    e.preventDefault();
    console.log("Delete ID:", adminID);

    try {
      const result = await deleteAdmin({
        variables: { id: adminID },
      });

      // Handle the result as needed, e.g., show a success message
      console.log("Admin deleted successfully", result);
      setmsg("Admin deleted successfully");
      setShow(false);
    } catch (error) {
      // Handle any errors
      console.error("Error deleting Admin", error);
      setmsg("Error deleting Admin");
    }
  };
  return (
    <React.Fragment>
      <div className="overflow-x-auto relative sm:rounded-lg px-2 md:px-6 lg:px-20 mx-auto mb-12">
        {msg === "Admin deleted successfully" ? (
          <Alert variant="filled" severity="success">
            {msg}
          </Alert>
        ) : msg === "Error deleting Admin" ? (
          <Alert variant="filled" severity="error">
            {msg}
          </Alert>
        ) : null}
        <table className="w-full text-sm text-left px-12 mt-3">
          <thead className=" bg-[#263238]">
            {/* bg-[#ab47bc] */}
            <tr>
              <th scope="col" className="py-3 px-6">
                <Typography
                  variant="subheader"
                  fontSize={{ xs: "15px", sm: "20px" }}
                  lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className=" uppercase"
                  color={"white"}
                >
                  Admins
                </Typography>
              </th>
              <th scope="col" className="py-3 px-6">
                <Typography
                  variant="subheader"
                  fontSize={{ xs: "15px", sm: "20px" }}
                  lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className=" uppercase"
                  color={"white"}
                >
                  Admin Since
                </Typography>
              </th>
              <th scope="col" className="py-3 px-6 text-center">
                <Typography
                  variant="subheader"
                  fontSize={{ xs: "15px", sm: "20px" }}
                  lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className=" uppercase"
                  color={"white"}
                >
                  Role
                </Typography>
              </th>
              <th scope="col" className="py-3 px-6 text-center">
                <Typography
                  variant="subheader"
                  fontSize={{ xs: "15px", sm: "20px" }}
                  lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className=" uppercase"
                  color={"white"}
                >
                  Action
                </Typography>
              </th>
            </tr>
          </thead>
          {currentData &&
            currentData.map((admin, index) => (
              <tbody key={index}>
                <tr className="bg-white border-b border-2 text-primary hover:bg-gray-200">
                  <th scope="row" className="py-4 px-6">
                    <Typography
                      variant="caption"
                      fontSize={{ xs: "13px", sm: "16px", md: "20px" }}
                      lineHeight={{ xs: "200%", sm: "180%" }}
                      letterSpacing={"0.02em"}
                      className="text-start"
                    >
                      {admin.firstname + " " + admin.lastname}
                    </Typography>
                    {/* </Link> */}
                  </th>

                  <td className="py-4 px-6">
                    <Typography
                      variant="caption"
                      fontSize={{ xs: "12px", sm: "13px", md: "16px" }}
                      lineHeight={{ xs: "200%", sm: "180%" }}
                      letterSpacing={"0.02em"}
                      className="text-start opacity-70"
                    >
                      {formatDay(admin.createdAt)}
                    </Typography>
                  </td>

                  <td className="py-4 px-6">
                    <Typography
                      variant="caption"
                      fontSize={{ xs: "12px", sm: "13px", md: "16px" }}
                      lineHeight={{ xs: "200%", sm: "180%" }}
                      letterSpacing={"0.02em"}
                      className={`"text-start opacity-70" `}
                    >
                      {admin.role}
                    </Typography>
                  </td>

                  <td className="py-4 px-6">
                    <Box
                      className={`${
                        admin.role === "superadmin" ||
                        adminData.role === "editor"
                          ? "hidden"
                          : "flex gap-2 items-center place-content-center !text-center"
                      }`}
                    >
                      {/* <IconButton
                        aria-label="edit"
                        onClick={() => {
                          handleEdit(admin.id);
                        }}
                        color="primary"
                      >
                        <Edit />
                      </IconButton> */}

                      <IconButton
                        aria-label="delete"
                        onClick={() => {
                          // handleDelete(admin.id);
                          setID(admin.id);
                          setShow(true);
                        }}
                        color="primary"
                        // className={`${
                        //   admin.role === "superadmin" ? "!hidden" : "!block"
                        // }`}
                      >
                        <Delete />
                      </IconButton>
                    </Box>
                  </td>
                </tr>
              </tbody>
            ))}
        </table>

        <div className=" mx-auto">
          <Pagination
            totalPosts={data.length}
            postsPerPage={postsPerPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </div>

        {show && (
          <ActionModal
            statement={"Do you wish to delete Admin?"}
            label1={"Delete Admin"}
            label2={"Cancel"}
            modalstate={show}
            closeModal={() => setShow(false)}
            handleDelete={(e) => handleDel(e, ID)}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default AdminTable;
