import React from "react";
import ForgotPassCard from "../../components/Auth/ForgotPassCard";
import PagesHeader from "../../custom/Layout/PagesHeader";

const ForgotPassword = () => {
  return (
    <React.Fragment>
      <PagesHeader
        imgSrc={
          "https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80"
        }
      >
        <ForgotPassCard />
        <br />
        <br />
        <br />
      </PagesHeader>
    </React.Fragment>
  );
};

export default ForgotPassword;
