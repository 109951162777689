import {
  Alert,
  Box,
  CircularProgress,
  FormGroup,
  Grid,
  TextField,
} from "@mui/material";
import React, { useContext, useState } from "react";

import CRCbutton from "../../../custom/Forms/CRCbutton";
import CRCFileUpload from "../../../custom/Forms/CRCFileUpload";
import CRCTextField from "../../../custom/Forms/CRCTextField";
import { LoaderContext } from "../../../context/loader.context";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { SEND_NEWSLETTER } from "../../../graphql/other-queries";
import CRCeditor from "../../../custom/Forms/CRCeditor";
import { formatDBdate } from "../../../hook/date-format";

const CreateNewsMail = () => {
  let navigate = useNavigate();
  const [submitClicked, setSubmitClicked] = React.useState(false);
  const [msg, setMsg] = useState("");
  const loaderContext = useContext(LoaderContext);

  const openLoader = () => {
    loaderContext.setShowState(true);
  };

  const closeLoader = () => {
    loaderContext.setShowState(false);
  };

  // const [addGallery, { loading, error, data }] = useMutation(ADD_GALLERY);

  // const name = userData.firstname + " " + userData.lastname;
  // console.log("Author: ", name);

  const [formData, setFormData] = useState({
    link: "",
    subject: "",
    message: "",
  });

  const handleContent = (message) => {
    setFormData({ ...formData, message });
    setMsg("");
  };

  // Using the LOGIN_ADMIN query conditionally based on submitClicked
  const { loading, error, data } = useQuery(SEND_NEWSLETTER, {
    variables: formData,
    skip: !submitClicked,
    onCompleted: (data) => {
      if (data && data.sendNewsLetter) {
        const email = data.sendNewsLetter.token;
        console.log("Email", email);
        // senMailToEmail(token)
        closeLoader();
        setMsg("Newsletter Sent successfully");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const emptyFields = Object.keys(formData).filter((key) => !formData[key]);

    if (emptyFields.length > 0) {
      console.log("Empty fields:", emptyFields);
      return;
    }

    // console.log("Form data:", formData);
    setSubmitClicked(true);
    openLoader();
  };
  return (
    <React.Fragment>
      <div className="overflow-x-hidden relative sm:rounded-lg px-2 md:px-6 lg:px-20 !mx-auto mb-12">
        <form onSubmit={handleSubmit}>
          <FormGroup>
            {/* {loading && <CircularProgress />} Display loading indicator */}
            {msg === "Newsletter Sent successfully" ? (
              <Alert variant="filled" severity="success">
                {msg}
              </Alert>
            ) : msg === "Please input all fields" ? (
              <Alert variant="filled" severity="error">
                {msg}
              </Alert>
            ) : msg === "Please Select an Image" ? (
              <Alert variant="filled" severity="error">
                {msg}
              </Alert>
            ) : null}
            <br />
            {/* Display error alert */}
            <Grid container spacing={{ xs: 0, sm: 2, md: 2 }}>
              <Grid item xs={6} sm={6} md={6}>
                <CRCTextField
                  label="Subject"
                  name="subject"
                  type="name"
                  onChange={(e) => {
                    setFormData({ ...formData, subject: e.target.value });
                    setMsg("");
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <CRCTextField
                  label="Url Link"
                  name="link"
                  type="name"
                  onChange={(e) => {
                    setFormData({ ...formData, link: e.target.value });
                    setMsg("");
                  }}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <CRCeditor value={formData.message} onChange={handleContent} />
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box className="mx-auto mt-8 w-[60%] md:w-[40%]">
                <CRCbutton label={"Send NewsLetter Mail"} type={"submit"} />
              </Box>
            </Grid>
          </FormGroup>
        </form>
      </div>
    </React.Fragment>
  );
};

export default CreateNewsMail;
