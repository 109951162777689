import * as React from "react";
import { useContext } from "react";
import { SessionModalContext } from "../../context/modal-session-context";
import Cookies from "universal-cookie";
import SessionModal from "./SessionModal";

export default function TokenSessionChecker() {
  const modalContext = useContext(SessionModalContext);

  const openModal = () => {
    modalContext.setOpen(true);
  };
  const closeModal = () => {
    modalContext.setOpen(false);
  };

  // Session Handling

  const cookies = new Cookies();

  React.useEffect(() => {
    const checkTokenExpiration = () => {
      const TOKEN_REFRESH_THRESHOLD = 5 * 60 * 1000;
      const adminToken = cookies.get("crc-admin-token");

      if (adminToken) {
        // Parse the JWT token to get its payload
        const tokenParts = adminToken.split(".");
        if (tokenParts.length === 3) {
          const payload = JSON.parse(atob(tokenParts[1]));
          // Get the expiration timestamp from the payload
          const expirationTime = payload.exp * 1000; // Convert to milliseconds

          const timeUntilExpiry = expirationTime - Date.now();

          // console.log("Session Checker");
          // console.log("expirationTimer", expirationTime);
          // console.log("timeUntilExpiry", timeUntilExpiry);

          if (timeUntilExpiry <= TOKEN_REFRESH_THRESHOLD) {
            // Token is about to expire within 5 minutes, initiate a refresh
            openModal();
          }
        }
        //   }
      }
    };

    // Check token expiration every 1 minutes
    const interval = setInterval(checkTokenExpiration, 60 * 1000);

    // Clean up interval on unmount
    return () => clearInterval(interval);
  }, []);

  //
  //
  // Check if Cookie exist, if not then close Session Modal;

  const checkCookie = () => {
    const adminToken = cookies.get("crc-admin-token");

    if (!adminToken) {
      // console.log("Cookie 'crc-admin-token' does not exist.");
      closeModal();
    }
  };

  React.useEffect(() => {
    // Call the checkCookie function initially
    checkCookie();

    // Set up an interval to call the checkCookie function every 10sec
    const intervalId = setInterval(checkCookie, 100);

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return <div>{modalContext.open && <SessionModal />}</div>;
}
