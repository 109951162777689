import "./App.css";

import { BrowserRouter as Router } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import Navigator from "./navigation/Navigator";
import CRCAppBar from "./custom/Layout/CRCAppBar";
import { AuthProvider } from "./context/app.context";
import LoaderProvider from "./context/loader.context";
import CRCFooter from "./custom/Layout/CRCFooter";
import SessionModalProvider from "./context/modal-session-context";
import ScrollToTopOnPageChange from "./hook/scroll-to-top";
import client from "./graphql/apollo-clientelle";

function App() {
  return (
    <>
      <Router>
        <ApolloProvider client={client}>
          <AuthProvider>
            <LoaderProvider>
              <SessionModalProvider>
                <CRCAppBar />
                <Navigator />
                <CRCFooter />
              </SessionModalProvider>
            </LoaderProvider>
          </AuthProvider>
        </ApolloProvider>
        <ScrollToTopOnPageChange />
      </Router>
    </>
  );
}

export default App;
