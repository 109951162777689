import { Avatar, Box, Typography, Grid, useMediaQuery } from "@mui/material";
import React from "react";
import { useQuery } from "@apollo/client";
import { useAuth } from "../../../context/app.context";
import { Link } from "react-router-dom";
import { formatDay } from "../../../hook/date-format";

const PlainAdminHeader = ({ children }) => {
  const { isAuthenticated, logoutUser, adminData } = useAuth();

  let image = `${process.env.REACT_APP_S3_BUCKET_URL}/designs/redline_cz1pcn.jpg`;

  // Inside your component
  const isMobileOrTablet = useMediaQuery("(max-width: 600px)"); // Adjust the max-width as needed
  const avatarSize = isMobileOrTablet ? 100 : 200;

  return (
    <>
      <Box className="relative flex h-screen content-center -z-50 items-center justify-center">
        <Box
          className={`absolute top-0 h-[50%]  w-full `}
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Box className="absolute top-0 h-[50%] w-full bg-black/75 bg-cover bg-center" />
        <Box className="grid md:grid-cols-4 lg:grid-cols-3 w-full place-content-between  absolute top-[60px] md:top-[80px] lg:top-[70px] ">
          {/* grid md:grid-cols-4 lg:grid-cols-3  place-content-between  absolute top-[45px] md:top-[80px] lg:top-[70px] left-5 md:left-[40px] lg:left-[66px] */}
          <Typography
            variant="header"
            component={"div"}
            color="white"
            fontSize={{ xs: "18px", sm: "20px", md: "25px" }}
            lineHeight={{ xs: "200%", sm: "180%" }}
            letterSpacing={"0.02em"}
            className="md:col-span-2 capitalize pl-4"
          >
            Welcome:{" "}
            {
              <>
                <Typography
                  variant="body"
                  color="white"
                  fontSize={{ xs: "16px", sm: "20px", md: "22px" }}
                  lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                >
                  {adminData.firstname + " " + adminData.lastname}
                </Typography>
              </>
            }
          </Typography>

          <Typography
            variant="header"
            component={"div"}
            color="white"
            fontSize={{ xs: "18px", sm: "18px", md: "20px" }}
            lineHeight={{ xs: "200%", sm: "180%" }}
            letterSpacing={"0.02em"}
            className="md:col-span-2 lg:col-span-1 pl-4 capitalize left-0 text-center"
          >
            {adminData.role} since:{" "}
            {
              <>
                <Typography
                  variant="body"
                  color="white"
                  fontSize={{ xs: "16px", sm: "16px", md: "20px" }}
                  lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className="col-span-2"
                >
                  {formatDay(adminData.createdAt)}
                </Typography>
              </>
            }
          </Typography>
        </Box>
      </Box>
      <Box className=" mx-auto place-content-center -mt-[650px] profilecard md:-mt-[900px] lg:-mt-[600px]   !h-full w-[90%]">
        <Box className="bg-white relative h-full rounded-2xl p-6 md:p-12">
          <Box className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <Avatar
              alt={adminData.firstname}
              src={adminData.avatar}
              sx={{ width: avatarSize, height: avatarSize }}
            />
          </Box>
          {children}
        </Box>
      </Box>
    </>
  );
};

export default PlainAdminHeader;
