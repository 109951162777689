import {
  Alert,
  Box,
  CircularProgress,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";

import "../../../../src/CustomEditor.css";
import { useQuery } from "@apollo/client";
import { GET_ARTICLE } from "../../../graphql/other-queries";
import PlainAdminHeader from "../UI/PlainAdminHeader";

const ViewArticle = () => {
  const [article, setArticle] = useState("");

  const params = useParams();

  const { data: articleData } = useQuery(GET_ARTICLE, {
    variables: { id: params.id },
    pollInterval: 500,
  });

  React.useEffect(() => {
    if (articleData) {
      // console.log("EVENTS", articleData.getArticlesById);
      //   Filter Articles by like
      const article = articleData.getArticlesById;
      setArticle(article);
    }
  }, [articleData]);
  return (
    <React.Fragment>
      <Box className={`relative quillEditor`}>
        <PlainAdminHeader>
          <Box className="mt-16">
            <div className="overflow-x-hidden relative sm:rounded-lg px-2 md:px-6 lg:px-20 !mx-auto mb-12">
              {/* Display loading indicator */}

              {/* Display error alert */}
              <Grid container spacing={{ xs: 0, md: 2 }}>
                <Grid item xs={12} sm={6} md={6}></Grid>
                <Box className="bg-white lg:px-20 md:px-16 px-6 py-12 h-auto ">
                  <Box className="text-center">
                    <Typography
                      variant="header"
                      fontSize={{ xs: "20px", sm: "30px" }}
                      lineHeight={{ xs: "200%", sm: "180%" }}
                      letterSpacing={"0.02em"}
                      className=" uppercase"
                      color="primary"
                    >
                      {article.title}
                    </Typography>
                  </Box>
                  <Box className=" mx-auto place-content-center w-full py-4">
                    <img
                      className="object-cover bg-cover place-content-center mx-auto h-full md:w-full"
                      src={article.contentpic}
                      alt={article.title}
                      title="Click to view more"
                    />
                  </Box>

                  {/* Article Editor Content */}

                  <Box className="overflow-hidden">
                    <div
                      style={{ wordWrap: "break-word", whiteSpace: "normal" }}
                      dangerouslySetInnerHTML={{ __html: article.content }}
                    />
                  </Box>
                </Box>
              </Grid>
            </div>
          </Box>
        </PlainAdminHeader>
      </Box>
    </React.Fragment>
  );
};

export default ViewArticle;
