import React, { useContext, useState } from "react";
import { Alert, Box, FormGroup, Grid } from "@mui/material";
import CRCTextField from "../../custom/Forms/CRCTextField";
import CRCbutton from "../../custom/Forms/CRCbutton";
import { LoaderContext } from "../../context/loader.context";
import { useMutation, useQuery } from "@apollo/client";
import { RENEW_ADMIN_PASSWORD } from "../../graphql/admin-queries";
import { useNavigate, useParams } from "react-router-dom";

const NewPasswordForm = () => {
  const [submitClicked, setSubmitClicked] = React.useState(false);
  const loaderContext = useContext(LoaderContext);
  const [password, setPassword] = useState("");
  const [copassword, setCoPassword] = useState("");

  const openLoader = () => {
    loaderContext.setShowState(true);
  };

  const closeLoader = () => {
    loaderContext.setShowState(false);
  };

  const [renewAdminPassword, { loading, error, data }] =
    useMutation(RENEW_ADMIN_PASSWORD);
  const [msg, setMsg] = useState("");
  let navigate = useNavigate();
  let params = useParams();

  console.log("Token", params.slug);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password === "") {
      // Set error state or display a message indicating the empty fields
      console.log("Empty fields");
      return;
    }

    if (password === copassword) {
      openLoader();
      try {
        const { data } = await renewAdminPassword({
          variables: { token: params.slug, password },
        });

        // Handle successful form submission (if needed)
        // console.log("Password renewed successfully:", data);

        if (data.renewAdminPassword.email === null) {
          closeLoader();
          setMsg("Email does not exists");
        } else {
          closeLoader();
          // console.log("Password updated successfully");
          setMsg("Password updated successfully");
          setTimeout(() => {
            navigate(`/signin`);
          }, 3000);
        }
      } catch (error) {
        // Display error alert for server error
        console.error("Signup error:", error.message);
      }
    } else {
      // console.log("Password does not match");
      setMsg("Password does not match");
    }

    // console.log("Form data:", password, copassword);
    // Your form submission logic here

    // Now added refresh Token just like sign in
    setSubmitClicked(true);
    // console.log("Forgot Password clicked");
  };

  return (
    <form onSubmit={handleSubmit} className="w-full md:w-3/4 lg:w-2/5">
      {msg === "Admin Not Found" ? (
        <Alert variant="filled" severity="error">
          {msg}
        </Alert>
      ) : msg === "Password does not match" ? (
        <Alert variant="filled" severity="error">
          {msg}
        </Alert>
      ) : msg === "Password updated successfully" ? (
        <Alert variant="filled" severity="success">
          {msg}
        </Alert>
      ) : null}
      <FormGroup className="">
        <Grid container spacing={{ xs: 0, sm: 2, md: 2 }} className="">
          <Grid item xs={12} md={12}>
            <CRCTextField
              label="New Password"
              name="password"
              type="password"
              onChange={(e) => {
                setPassword(e.target.value);
                setMsg("");
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <CRCTextField
              label="Confirm Password"
              name="copassword"
              type="password"
              onChange={(e) => {
                setCoPassword(e.target.value);
                setMsg("");
              }}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <Box className="mx-auto mt-8">
              <CRCbutton label={"Submit"} type={"submit"} />
            </Box>
          </Grid>
        </Grid>
      </FormGroup>
    </form>
  );
};

export default NewPasswordForm;
