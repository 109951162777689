import {
  Box,
  IconButton,
  Typography,
  Alert,
  Avatar,
  Divider,
} from "@mui/material";
import React from "react";
import { formatDay } from "../../../hook/date-format";
import { Link, useNavigate } from "react-router-dom";

import { Visibility, Delete } from "@mui/icons-material";
import { DELETE_GALLERY } from "../../../graphql/other-queries";
import { useMutation } from "@apollo/client";
import ActionModal from "../../../custom/Layout/ActionModal";
import CRCDialog from "../../../custom/Dialog/CRCDialog";
import { useState } from "react";
import Pagination from "../../../custom/Pagination/Pagination";

const PartnersMediaTable = ({ data }) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage, setPostsPerPage] = React.useState(10);

  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentData = data.slice(firstPostIndex, lastPostIndex);

  let navigate = useNavigate();
  const [show, setShow] = useState(false);

  const [gallery, setGallery] = useState("");

  const [msg, setmsg] = useState("");
  const [ID, setID] = useState(0);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (clickedGallery) => {
    setGallery(clickedGallery);
    setOpen(true);
    // console.log("We are open", open);
    // console.log("Gallery", clickedGallery);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [deleteGallery, { loading, error, data: galleryData }] =
    useMutation(DELETE_GALLERY);

  const handleView = (galleryID) => {
    // galleryID.preventDefault();

    // console.log("Edit ID:", galleryID);
    navigate(`/gallery/${galleryID}`);
  };

  const handleDel = async (e, galleryID) => {
    e.preventDefault();
    console.log("Delete ID:", galleryID);

    try {
      const result = await deleteGallery({
        variables: { id: galleryID },
      });

      // Handle the result as needed, e.g., show a success message
      console.log("Gallery deleted successfully", result);
      setmsg("Gallery deleted successfully");
      setShow(false);
    } catch (error) {
      // Handle any errors
      console.error("Error deleting Gallery", error);
      setmsg("Error deleting Gallery");
    }
  };
  return (
    <React.Fragment>
      <div className="overflow-x-auto relative sm:rounded-lg px-2 md:px-6 lg:px-20 mx-auto mb-12">
        {msg === "Gallery deleted successfully" ? (
          <Alert variant="filled" severity="success">
            {msg}
          </Alert>
        ) : msg === "Error deleting Gallery" ? (
          <Alert variant="filled" severity="error">
            {msg}
          </Alert>
        ) : null}
        <br />
        <table className="w-full text-sm text-left px-12 mt-3">
          <thead className=" bg-[#263238]">
            {/* bg-[#ab47bc] */}
            <tr>
              <th scope="col" className="py-3 px-6">
                <Typography
                  variant="subheader"
                  fontSize={{ xs: "15px", sm: "20px" }}
                  lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className=" uppercase"
                  color={"white"}
                >
                  Gallery Image
                </Typography>
              </th>
              <th scope="col" className="py-3 px-6">
                <Typography
                  variant="subheader"
                  fontSize={{ xs: "15px", sm: "20px" }}
                  lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className=" uppercase"
                  color={"white"}
                >
                  Title
                </Typography>
              </th>
              <th scope="col" className="py-3 px-6 text-center">
                <Typography
                  variant="subheader"
                  fontSize={{ xs: "15px", sm: "20px" }}
                  lineHeight={{ xs: "200%", sm: "180%" }}
                  letterSpacing={"0.02em"}
                  className=" uppercase"
                  color={"white"}
                >
                  Action
                </Typography>
              </th>
            </tr>
          </thead>
          {currentData &&
            currentData.map((gallery, index) => (
              <tbody key={index}>
                <tr className="bg-white border-b border-2 text-primary hover:bg-gray-200">
                  <th scope="row" className="py-4 px-6">
                    {/* <Typography
                      variant="caption"
                      fontSize={{ xs: "13px", sm: "16px", md: "20px" }}
                      lineHeight={{ xs: "200%", sm: "180%" }}
                      letterSpacing={"0.02em"}
                      className="text-start"
                    >
                      {gallery.title}
                    </Typography> */}

                    {gallery.imgSrc.includes(
                      "https://crc-resources.s3.amazonaws.com/gallery"
                    ) ? (
                      <Avatar
                        alt={gallery.title}
                        src={gallery.imgSrc}
                        variant="square"
                        // sx={{ width: 40, height: 40 }}
                      />
                    ) : (
                      <Avatar
                        alt={gallery.title}
                        src="https://www.freeiconspng.com/thumbs/youtube-logo-png/hd-youtube-logo-png-transparent-background-20.png"
                        variant="square"
                        // sx={{ width: 40, height: 40 }}
                      />
                    )}
                  </th>
                  <td className="py-4 px-6">
                    <Typography
                      variant="caption"
                      fontSize={{ xs: "12px", sm: "13px", md: "16px" }}
                      lineHeight={{ xs: "200%", sm: "180%" }}
                      letterSpacing={"0.02em"}
                      className="text-start opacity-70"
                    >
                      {gallery.title}
                    </Typography>
                  </td>
                  <td className="py-4 px-6">
                    {/* <Typography
                      variant="caption"
                      fontSize={{ xs: "12px", sm: "13px", md: "16px" }}
                      lineHeight={{ xs: "200%", sm: "180%" }}
                      letterSpacing={"0.02em"}
                      className={`"text-start opacity-70" `}
                    >
                      {gallery.status}
                    </Typography> */}
                    <Box className="flex gap-2 items-center place-content-center !text-center">
                      <IconButton
                        aria-label="view"
                        onClick={() => {
                          // handleView(gallery.id);
                          handleClickOpen(gallery);
                        }}
                        color="primary"
                      >
                        <Visibility />
                      </IconButton>

                      <IconButton
                        aria-label="delete"
                        onClick={() => {
                          // handleDelete(gallery.id);
                          setID(gallery.id);
                          setShow(true);
                        }}
                        color="primary"
                      >
                        <Delete />
                      </IconButton>
                    </Box>
                  </td>
                </tr>
              </tbody>
            ))}
        </table>

        <div className=" mx-auto">
          <Pagination
            totalPosts={data.length}
            postsPerPage={postsPerPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </div>

        {show && (
          <ActionModal
            statement={"Do you wish to delete Gallery?"}
            label1={"Delete"}
            label2={"Cancel"}
            modalstate={show}
            closeModal={() => setShow(false)}
            handleDelete={(e) => handleDel(e, ID)}
          />
        )}

        <CRCDialog title={gallery.title} onClose={handleClose} open={open}>
          <Box className="grid w-full">
            {gallery.imgSrc?.includes(
              "https://crc-resources.s3.amazonaws.com/gallery"
            ) ? (
              <img
                className="object-cover shadow-md drop-shadow-md bg-cover place-content-center mx-auto h-full w-full"
                src={gallery.imgSrc}
                alt={gallery.title}
                title="Click to view more"
              />
            ) : (
              <iframe
                width="560"
                height="315"
                src={gallery.imgSrc}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            )}

            <Divider />
            <Typography
              variant="body"
              fontSize={{ xs: "13px", sm: "13px" }}
              lineHeight={{ xs: "200%", sm: "180%" }}
              letterSpacing={"0.02em"}
              className="py-3 capitalize"
            >
              {gallery.description}
            </Typography>
          </Box>
        </CRCDialog>
      </div>
    </React.Fragment>
  );
};

export default PartnersMediaTable;
