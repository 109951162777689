import React from "react";
import { Box, Tabs, Tab } from "@mui/material";
import { useQuery } from "@apollo/client";
import PlainAdminHeader from "./UI/PlainAdminHeader";
import { GET_SUBSCRIBER } from "../../graphql/other-queries";
import { useState } from "react";
import { useEffect } from "react";
import SubscriberTable from "./NewsLetter/SubscriberTable";
import CreateNewsMail from "./NewsLetter/CreateNewsMail";
// import { card1, card2 } from "./UI/GetStats";

const NewsLetter = () => {
  const [news, setNews] = useState("");

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { data: newsData } = useQuery(GET_SUBSCRIBER, {
    // pollInterval: 500,
  });

  // Get All Subscribers
  useEffect(() => {
    if (newsData) {
      // console.log(newsData.getSubscriber);
      setNews(newsData.getSubscriber);
    }
  }, [newsData]);

  return (
    <PlainAdminHeader>
      <Box className="mt-16">
        <Box className="pt-5" display="flex" justifyContent="center">
          <Tabs value={value} onChange={handleChange} className="">
            <Tab label="Newsletter" className="" />
            <Tab label="Create NewsLetter" />
          </Tabs>
        </Box>
        {/* Content for the selected tab */}
        <Box className="py-8">
          {value === 0 && <SubscriberTable data={news} />}
          {value === 1 && <CreateNewsMail />}
        </Box>
      </Box>
    </PlainAdminHeader>
  );
};

export default NewsLetter;
