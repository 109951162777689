import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import { useContext } from "react";
import { Box, Paper, Typography } from "@mui/material";
import CRCbutton from "../Forms/CRCbutton";
import { SessionModalContext } from "../../context/modal-session-context";
import { useQuery } from "@apollo/client";
import { useAuth } from "../../context/app.context";
import { REFRESH_ADMIN_SESSION } from "../../graphql/admin-queries";

export default function SessionModal() {
  const { isAuthenticated, adminData, loginAdmin, logoutAdmin } = useAuth();

  const [submitClicked, setSubmitClicked] = React.useState(false);
  const modalContext = useContext(SessionModalContext);

  const openModal = () => {
    modalContext.setOpen(true);
  };
  const closeModal = () => {
    logoutAdmin();
    modalContext.setOpen(false);
  };
  const handleRefreshSession = async (event) => {
    event.preventDefault();

    // Now added refresh Token just like sign in
    setSubmitClicked(true);
    modalContext.setOpen(false);
    console.log("Refresh clicked");

    try {
      // Use the data obtained from useQuery here
      if (data && data.refreshAdminToken) {
        const token = data.refreshAdminToken.token;
        console.log("Refreshed Token", token);
        loginAdmin(token);
      }
    } catch (error) {
      console.error("Error refreshing session:", error);
    }
  };

  // Using the REFRESH_ADMIN_SESSION query conditionally based on submitClicked
  const { loading, error, data } = useQuery(REFRESH_ADMIN_SESSION, {
    variables: { adminId: adminData.id },
    // skip: !submitClicked,
  });

  // Session Handling

  // const cookies = new Cookies();

  // const TOKEN_REFRESH_THRESHOLD = 5 * 60 * 1000; // 5 minutes in milliseconds

  // const userToken = cookies.get("crc-user-token");

  // React.useEffect(() => {
  //   if (userToken) {
  //     // Parse the JWT token to get its payload
  //     const tokenParts = userToken.split(".");
  //     if (tokenParts.length === 3) {
  //       const payload = JSON.parse(atob(tokenParts[1]));
  //       // Get the expiration timestamp from the payload
  //       const expirationTime = payload.exp * 1000; // Convert to milliseconds

  //       const timeUntilExpiry = expirationTime - Date.now();

  //       console.log("Session Checker");
  //       console.log("expirationTimer", expirationTime);
  //       console.log("timeUntilExpiry", timeUntilExpiry);

  //       if (timeUntilExpiry <= TOKEN_REFRESH_THRESHOLD) {
  //         // Token is about to expire within 5 minutes, initiate a refresh
  //         openModal();
  //       }

  //       const refreshInterval = setInterval(() => {
  //         if (timeUntilExpiry <= TOKEN_REFRESH_THRESHOLD) {
  //           openModal();
  //         }
  //       }, TOKEN_REFRESH_THRESHOLD);

  //       return () => {
  //         clearInterval(refreshInterval);
  //       };
  //     }
  //   }
  // }, [userToken]);

  return (
    <div>
      {/* <Button  onClick={handleOpen}>Show backdrop</Button> */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openModal}
        // onClick={closeModal}
      >
        <Paper elevation={3} className="w-[300px] md:w-[600px] p-4 rounded-md">
          <Box>
            <Typography
              variant="body"
              lineHeight={{ xs: "200%", sm: "180%" }}
              fontSize={{ xs: "20px", sm: "30px" }}
              letterSpacing={"0.02em"}
              className="pl-6"
            >
              Do you wish to continue this session ?
            </Typography>
          </Box>
          <Box className="mx-auto mt-8 p-4 flex gap-24 place-content-center">
            <form onSubmit={handleRefreshSession}>
              <CRCbutton
                rounded
                label={"Continue"}
                type={"submit"}
                onClick={handleRefreshSession} // Pass the onClick event to the button
              />
            </form>
            <CRCbutton rounded onClick={closeModal} label={"Logout Session"} />
          </Box>
        </Paper>
      </Backdrop>
    </div>
  );
}
