import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { Link, useNavigate } from "react-router-dom";
import { IconButton, Drawer } from "@mui/material";
import { useAuth } from "../../context/app.context";
import { adminEditorPagesIcon, adminPagesIcon } from "../../static/static";

const settings = ["Profile", "Account", "Dashboard", "Logout"];

function CRCAppBar() {
  const [scrollBackground, setScrollBackground] = useState(false);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

  const isBigScreen = window.innerWidth > 700; // Adjust the breakpoint as needed

  // Getting login state
  const { isAuthenticated, logoutAdmin, adminData } = useAuth();
  const loginState = isAuthenticated;

  let navigate = useNavigate();
  const adminIcons =
    adminData.role !== "editor" ? adminPagesIcon : adminEditorPagesIcon;

  // console.log(adminIcons);
  const handleLogout = () => {
    logoutAdmin();
    setMobileDrawerOpen(!mobileDrawerOpen);
    navigate("/");
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrollBackground(true);
    } else {
      setScrollBackground(false);
    }
  };

  const toggleMobileDrawer = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: scrollBackground ? "primary" : "transparent",
          transition: "background-color 0.3s",
          boxShadow: scrollBackground
            ? "0px 1px 5px rgba(0, 0, 0, 0.5)"
            : "none",
        }}
      >
        <Toolbar disableGutters>
          <Box style={{ display: "flex", alignItems: "center" }}>
            {/* <AdbIcon style={{ marginRight: 1 }} /> */}

            <Avatar
              alt="CRC"
              src={`${process.env.REACT_APP_S3_BUCKET_URL}/designs/crc512_r3usay.png`}
              style={{ marginRight: 5 }}
            />
            <Typography
              variant="subheader"
              noWrap
              component={Link}
              to="/"
              style={{
                marginRight: 1,
                fontFamily: "PlayFair Display",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: scrollBackground ? "#FFF" : "#FFF",
                textDecoration: "none",
              }}
            >
              CRC Admin
            </Typography>
          </Box>

          <Box
            style={{ flexGrow: 0 }}
            className=" flex place-content-center ml-auto mr-2"
          >
            {loginState === false ? (
              <Typography
                variant="subtitle"
                color="inherit"
                noWrap
                component={Link}
                to="/signin"
                className="flex place-content-end"
              >
                Sign in
              </Typography>
            ) : null}
            {loginState === true ? (
              <IconButton
                onClick={toggleMobileDrawer}
                style={{ padding: 0, marginRight: 5 }}
                className="flex !place-content-end "
              >
                <Avatar alt={adminData.firstname} src={adminData.avatar} />
              </IconButton>
            ) : null}
          </Box>
        </Toolbar>
      </AppBar>

      {/* Mobile */}
      <Drawer
        anchor="right"
        open={mobileDrawerOpen}
        onClose={toggleMobileDrawer}
        sx={{
          display: {
            sm: "block",
            xs: "block",
            fontFamily: "Bricolage Grotesque",
          },
        }} // Show only on mobile
      >
        <Box
          sx={{
            width: 250,
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
            paddingTop: 2,
          }}
        >
          {loginState === true ? (
            <Box className="flex place-content-center">
              <Avatar
                alt={adminData.firstname}
                src={adminData.avatar}
                sx={{ width: 60, height: 60, marginBottom: 1 }}
              />
            </Box>
          ) : null}

          <Typography
            variant="subheader"
            className="!mb-4"
            align="center"
            gutterBottom
          >
            {adminData.firstname}
          </Typography>
          {adminIcons.map((page) => (
            <Link
              key={page.name}
              to={page.path} // Assuming your routes follow this pattern
              onClick={
                page.name === "Logout" ? handleLogout : toggleMobileDrawer
              }
              style={{ textDecoration: "none", color: "inherit" }}
              className="flex px-12"
            >
              <MenuItem>
                <IconButton
                  style={{
                    color: scrollBackground ? "#40404" : "#40440",
                  }}
                >
                  {page.icon}
                </IconButton>
                <Typography variant="body" align="center">
                  {page.name}
                </Typography>
              </MenuItem>
            </Link>
          ))}
        </Box>
      </Drawer>
    </>
  );
}

export default CRCAppBar;
