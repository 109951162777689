// CRCSelect.js
import React, { useState } from "react";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const CRCSelect = ({ label, values, placeholder, onChange }) => {
  const [selectedValue, setSelectedValue] = useState("");

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
    onChange(event.target.value); // Call the onChange callback passed from the parent
  };
  return (
    <Box
      component="div"
      sx={{
        "& .MuiFormControl-root": {
          m: 1,
          width: "100%",
          fontFamily: "Bricolage Grotesque",
          maxWidth: "100%",
        },
      }}
    >
      <FormControl variant="standard">
        <InputLabel>{label}</InputLabel>
        <Select
          value={selectedValue}
          onChange={handleSelectChange}
          required
          sx={{
            fontFamily: "Bricolage Grotesque",
          }}
        >
          <MenuItem value="" disabled>
            {placeholder}
          </MenuItem>
          {values.map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default CRCSelect;
