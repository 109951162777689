import { Box, Typography } from "@mui/material";
import React from "react";

const PagesHeader = ({ children, imgSrc }) => {
  let image = imgSrc;

  return (
    <>
      <Box className="relative flex h-screen content-center -z-50 items-center justify-center">
        <Box
          className={`absolute top-0 h-[50%] w-full `}
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Box className="absolute top-0 h-[50%] w-full bg-black/75 bg-cover bg-center" />
      </Box>
      <Box className=" mx-auto place-content-center -mt-[700px] md:-mt-[900px] lg:-mt-[600px]    !h-full w-[90%]">
        <Box className="bg-white h-full rounded-2xl p-6 md:p-12">
          {children}
        </Box>
      </Box>
    </>
  );
};

export default PagesHeader;
