import { gql } from "@apollo/client";

// Get all Admins
export const GET_ADMINS = gql`
  query Admin {
    getAdmins {
      id
      firstname
      lastname
      email
      avatar
      role
      createdAt
    }
  }
`;

// Get all Admins except superadmin
export const GET_MINOR_ADMINS = gql`
  query Admin {
    getMinorAdmins {
      id
      firstname
      lastname
      email
      avatar
      role
      createdAt
    }
  }
`;

// Get all Users
export const GET_USERS = gql`
  query User {
    getUsers {
      id
      firstname
      lastname
      email
      avatar
      gender
      dob
      social
      country
      totalInteractions
      createdAt
    }
  }
`;

// View a single Admin by id
export const GET_ADMIN = gql`
  query Admin($id: ID!) {
    getAdminById(id: $id) {
      id
      firstname
      lastname
      email
      avatar
      role
      createdAt
    }
  }
`;

// Login Admin by email and password
export const LOGIN_ADMIN = gql`
  query Admin($email: String!, $password: String!) {
    loginAdmin(email: $email, password: $password) {
      token
    }
  }
`;

// Refresh ADMIN Session Token
export const REFRESH_ADMIN_SESSION = gql`
  query Admin($adminId: ID!) {
    refreshAdminToken(adminId: $adminId) {
      token
    }
  }
`;

// Refresh Admin Session Token
export const ADMIN_FORGOT_PASSWORD = gql`
  query Admin($email: String!) {
    forgotAdminPassword(email: $email) {
      token
    }
  }
`;

// Sign up Admin
export const SIGNUP_ADMIN = gql`
  mutation SignUpAdminMutation(
    $firstname: String!
    $lastname: String!
    $avatar: Upload!
    $email: String!
    $password: String!
    $role: String!
  ) {
    signUpAdmin(
      email: $email
      password: $password
      firstname: $firstname
      lastname: $lastname
      avatar: $avatar
      role: $role
    ) {
      id
      email
      firstname
      lastname
      avatar
      role
      createdAt
    }
  }
`;

// Create A Partner
export const CREATE_PARTNER = gql`
  mutation CreatePartnerMutation(
    $name: String!
    $description: String!
    $imgSrc: Upload!
  ) {
    createPartner(name: $name, description: $description, imgSrc: $imgSrc) {
      id
      name
      description
      imgSrc
      createdAt
    }
  }
`;

// Get all Partners
export const GET_PARTNERS = gql`
  query Partner {
    getPartners {
      id
      name
      description
      imgSrc
      slug
      createdAt
    }
  }
`;

// View a single Partner by id
export const GET_PARTNER = gql`
  query Partner($id: ID!) {
    getPartnerById(id: $id) {
      id
      name
      description
      imgSrc
      slug
      createdAt
    }
  }
`;

// Renew Admin Password
export const RENEW_ADMIN_PASSWORD = gql`
  mutation RenewAdminPasswordMutation($token: String!, $password: String!) {
    renewAdminPassword(token: $token, password: $password) {
      email
    }
  }
`;

// DELETE MUTATIONS

// Delete a User
export const DELETE_USER = gql`
  mutation DeleteUserMutation($id: ID!) {
    deleteUser(id: $id) {
      id
    }
  }
`;
// Delete a Admin
export const DELETE_ADMIN = gql`
  mutation DeleteAdminMutation($id: ID!) {
    deleteAdmin(id: $id) {
      id
    }
  }
`;
// Delete a Admin
export const DELETE_PARTNER = gql`
  mutation DeletePartnerMutation($id: ID!) {
    deletePartner(id: $id) {
      id
    }
  }
`;
